import http from "../http";

const delet = (table, pk, cid) => {
    return http.delete("/delete/" + table + "?pk=" + pk + "&cid=" + cid);
};

const read = (table, limit, offset, order) => {
    return http.get("/list/" + table + "?limit=" + limit + "&offset=" + offset + "&order=" + order.join("|||"));
};

export default {
    delet,
    read
};
