import React, { useState, useLayoutEffect, useRef } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { HttpInterceptors } from './HttpInterceptors';
import { BsFillPieChartFill, BsListColumnsReverse, BsPower, BsGraphUp, BsAt, BsFillPersonBadgeFill, BsBoxArrowInRight, BsHammer, BsInfoCircle, BsViewStacked, BsFillAwardFill } from "react-icons/bs";
import { Nav, Navbar } from "react-bootstrap";
import './custom.scss';
import "./App.css";

import Index from "./components/Index";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Resultats from "./components/Resultats";
import Comparatifs from "./components/Comparatifs";
import Evolutions from "./components/Evolutions";
//import Docs from "./components/Docs";
import Contact from "./components/Contact";
import Admin from "./components/Admin";
import Profil from "./components/Profil";
import Transporteur from "./components/Transporteur";
import AideConnexion from "./components/AideConnexion";
import Mentions from "./components/Mentions";
import Passe from "./components/Passe";

const App = () => {
  const ito = useRef(), loggeduser = useRef();
  const location = useLocation(), navigate = useNavigate();
  let leftnav = [], rightnav = [], bottombar = [<Nav.Link key="0" as={Link} to="/mentions" eventKey="/mentions"><BsFillAwardFill className="opl" />&nbsp; Mentions légales</Nav.Link>];

  const getuser = () => {
    let outi = localStorage.getItem('outi');
    loggeduser.current = outi ? JSON.parse(outi) : {};
    let str = loggeduser.current.cfname || '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const [uname, setUname] = useState(getuser());

  useLayoutEffect(() => {
    //On_Mount
    function changeStorage() {
      setUname(getuser());
    }
    clearInterval(ito.current);
    if (loggeduser.current.cid) {
      localStorage.setItem('idletime', 600000); //600000
      const saveaction = (e) => {
        //console.log("saveaction", Date.now());
        localStorage.setItem('lastaction', Date.now());
      }
      const trackidle = () => {
        //console.log("trackidle", loggeduser, Date.now());
        let creqts = parseInt(localStorage.getItem('creqts') || 0);
        if (creqts > 0) {
          localStorage.setItem('lastaction', Date.now());
        } else {
          if (Date.now() - parseInt(localStorage.getItem('lastaction')) > parseInt(localStorage.getItem('idletime'))) {
            //console.log("doidle", Date.now() - parseInt(localStorage.getItem('lastaction')), parseInt(localStorage.getItem('idletime')));
            navigate("/logout/2", { replace: true });
          }
        }
      }
      document.onmousemove = saveaction;
      document.onclick = saveaction;
      document.onkeydown = saveaction;
      document.ontouchstart = saveaction;
      document.ontouchmove = saveaction;
      ito.current = setInterval(trackidle, 60000); //60000
    }
    else {
      document.onmousemove = undefined;
      document.onclick = undefined;
      document.onkeydown = undefined;
      document.ontouchstart = undefined;
      document.ontouchmove = undefined;
    }
    window.addEventListener('storage', changeStorage);
    document.querySelectorAll('.btn.nav-link').forEach((button) => button.classList.remove("nav-link"));
    //document.querySelectorAll('.nav-item.dropdown').forEach((button) => button.blur());
    window.isMobile = window.innerWidth <= 767;
    window.globalperiod = "GLOBAL";

    //On_Unmount
    return () => { clearInterval(ito.current); window.removeEventListener('storage', changeStorage); }
  }, [location]);

  if (loggeduser.current.cid) {
    leftnav.push(<Nav.Link key="0" as={Link} to="/resultats" eventKey="/resultats"><BsFillPieChartFill className="opl" />&nbsp; Résultats</Nav.Link>,
      <Nav.Link key="1" as={Link} to="/evolutions" eventKey="/evolutions"><BsGraphUp className="opl" />&nbsp; Évolutions</Nav.Link>,
      <Nav.Link key="2" as={Link} to="/comparatifs" eventKey="/comparatifs"><BsListColumnsReverse className="opl" />&nbsp; Comparatifs</Nav.Link>/*,
      <Nav.Link key="3" as={Link} to="/docs" eventKey="/docs" title="Documentation"><BsBookHalf className="opl" />&nbsp; Docs</Nav.Link>*/);
    if (loggeduser.current.cgroupe.indexOf(",") != -1) {
      rightnav.push(<Nav.Link key="3" as={Link} to="/transporteur" state={{ from: location.pathname }} eventKey="/transporteur"><BsViewStacked className="opl" />&nbsp; Transporteur</Nav.Link>);
    }
    if (loggeduser.current.cutype < 5) {
      rightnav.push(<Nav.Link key="2" as={Link} to="/admin" eventKey="/admin"><BsHammer className="opl" />&nbsp; Accès Admin</Nav.Link>);
    }
    rightnav.push(<Nav.Link key="0" as={Link} to={"/profil/" + loggeduser.current.cid} eventKey="/profil" title="Mon Profil"><BsFillPersonBadgeFill className="opl" />&nbsp; Bienvenue {uname}</Nav.Link>, <Nav.Link key="1" as={Link} to="/logout" eventKey="/logout" className="btn nv btn-warning"><BsPower className="opl" />Déconnexion</Nav.Link>);
    bottombar.push(<Nav.Link key="10" as={Link} to="/contact" eventKey="/contact"><BsAt className="opl" />&nbsp; Contact</Nav.Link>);
  }
  else {
    if (location.pathname != "/login") rightnav.push(/*<Nav.Link key="1" as={Link} to="/aideconnexion" eventKey="/aideconnexion"><BsInfoCircle className="opl" />&nbsp; Aide à la connexion</Nav.Link>, */<Nav.Link key="0" as={Link} to="/login" eventKey="/login" className="btn nv btn-info"><BsBoxArrowInRight className="opl" />Connexion</Nav.Link>);
    leftnav.push(<Nav.Link key="10" as={Link} to="/contact" eventKey="/contact"><BsAt className="opl" />&nbsp; Contact</Nav.Link>);
  }

  return (
    <div className="App">
      <Navbar collapseOnSelect expand="md" bg="dark" variant="light" sticky="top">
        <Navbar.Brand as={Link} to="/" title="Page d'accueil">
          <img src={require('./images/logo.png')} alt="IDFM-MV2" className="simg" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="jcsb">
          <Nav variant="pills" activeKey={location.pathname} defaultActiveKey="/" className="mr-auto">
            {leftnav}
          </Nav>
          <Nav variant="pills" activeKey={location.pathname}>
            {rightnav}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <HttpInterceptors />
      <div className="container dfdc mt-3">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/:tabid" element={<Admin />} />
          <Route path="/profil/:id" element={<Profil />} />
          <Route path="/passe" element={<Passe />} />
          <Route path="/passe/:cid" element={<Passe />} />
          <Route path="/transporteur" element={<Transporteur />} />
          <Route path="/resultats" element={<Resultats />} />
          <Route path="/comparatifs" element={<Comparatifs />} />
          <Route path="/evolutions" element={<Evolutions />} />
          {/*<Route path="/docs" element={<Docs />} />*/}
          <Route path="/contact" element={<Contact />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/logout/:msg" element={<Logout />} />
          <Route path="/aideconnexion" element={<AideConnexion />} />
          <Route path="/mentions" element={<Mentions />} />
          <Route index element={<Index />} />
          <Route path=":msg" element={<Index />} />
        </Routes>
      </div>
      <Navbar bg="dark" variant="light" sticky="bottom">
        <Navbar.Collapse className="jcsb">
          <Nav variant="pills" activeKey={location.pathname} defaultActiveKey="/" className="dfac">
            {bottombar}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default App;
