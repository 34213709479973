import React, { useState, useLayoutEffect, useRef } from "react";
import { BsTrash } from "react-icons/bs";
import { clearstore } from "../helpers";
import { Spinner, Form, Button } from "react-bootstrap";
import FilesService from "../services/FilesService";
import FormInputs from "./FormInputs";
import InfiniteScroll from "react-infinite-scroll-component";

const Files = (props) => {
    const inpt = useRef([]), folder = props.tb.folder || "datafiles", fltr = props.fltr || "a";
    const [progress, setProgress] = useState(0);
    const [selectedfiles, setSelectedfiles] = useState();
    const [selectedfile, setSelectedfile] = useState();
    const [files, setFiles] = useState([]);
    const [order, setOrder] = useState(['cid', 'DESC']);
    const [deltfile, setDeltfile] = useState({});
    const [hasmore, setHasmore] = useState(true);
    const inpts = props.tb.inpts || {};
    const [inputs, setInputs] = useState(Object.fromEntries(Object.entries(inpts).map(([key, val]) => [key, val[1]])));

    const initcto = (m, t, a, p) => {
        props.initcto(m, t);
        if (a) props.setProcessing(props.processing.replace(a, ""));
        if (p) {
            if (p.cid) {
                setDeltfile({ ...deltfile, [p.cid]: null });
            }
        }
        else {
            setProgress(0);
            inpt.current[0]?.setinitial(undefined, 1);
            if (p === 0) {
                setSelectedfiles();
                inpt.current[2].value = "";
            }
            else {
                setSelectedfile();
                inpt.current[1].value = "";
            }
        }
        //In MySQL: SHOW GLOBAL VARIABLES LIKE '%innodb_old_blocks_time%'; SET GLOBAL innodb_old_blocks_time=0;
    }

    const gfiles = () => {
        setHasmore(true);
        read(0);
        clearstore(folder + '_');
    }

    const upload = (sf, p, c) => {
        if (!sf.length) {
            initcto("Veuillez charger un fichier !", "danger");
            return;
        }

        var alrt = false, arr = Object.keys(inpts), arrlen = arr.length;
        for (let i = 0; i < arrlen; i++) { if (inpts[arr[i]][0].slice(-2) == " *" && !inputs[arr[i]]) { alrt = true; break; } }
        if (alrt) {
            props.initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
            return;
        }

        props.setProcessing(props.processing + "-1");
        if (!c) {
            setProgress(0);
        }

        FilesService.upload(folder, props.tb.accept, sf, p, c, inputs, (event) => setProgress(Math.round((100 * event.loaded) / event.total)))
            .then((parr) => {
                parr.map((val, idx) => {
                    if (val.status == "fulfilled") {
                        initcto(val.value.data.message, "info", "-1", p);
                        gfiles();
                    }
                    else {
                        let cb = () => initcto(val.reason.response.data ? val.reason.response.data.message : "Impossible de charger le(s) fichier(s) " + (sf.length == 1 ? ": " + sf[0].name : "du répertoire choisi") + " ! Vérifiez le format du fichier et réessayez.", "danger", "-1", p);
                        if (val.reason.response.status == 400) {
                            return props.setModalv([true, () => { props.setModalv([]); cb(); }, "Alerte", "Un ou plusieurs fichiers avec le(s) même(s) nom(s) existent déjà sur le serveur. Voulez-vous continuer en le(s) remplacant avec le(s) nouveau(x) fichier(s) chargé(s) ?\nCela impliquera également le remplacement des données dans la base.", [<Button key="0" variant="secondary" onClick={() => { props.setModalv([]); cb(); }}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); upload(sf, p, 1); }}>Confirmer</Button>]]);
                        }
                        cb();
                    }
                });
            });
    }

    const delet = (file, label) => {
        props.setProcessing(props.processing + "-2");
        setDeltfile({ ...deltfile, [file.cid]: 1 });

        FilesService.delet(folder, props.tb.accept, { file })
            .then((response) => {
                initcto(response.data.message, "info", "-2", file);
                let f = files.slice(0), tempv = f.findIndex(elm => elm.cid == file.cid);
                if (tempv != -1) {
                    f.splice(tempv, 1);
                    setFiles(f);
                }
            })
            .catch((err) => {
                initcto(err.response.data ? err.response.data.message : "Impossible de supprimer " + props.tb.hint + " : " + label, "danger", "-2", file);
            });
    }

    const read = (offset) => {
        if(offset === null || offset === undefined) offset = files.length;
        FilesService.read(folder, props.tb.accept, 20, offset, order).then((response) => {
            if(response.data.files && response.data.files.length) {
                setFiles(offset ? files.concat(response.data.files) : response.data.files);
            }
            else {
                if(!offset && files.length) setFiles([]);
                setHasmore(false);
            }
            localStorage.setItem("url" + folder, response.data.url);
        }).catch((err) => { });
    }

    const cleandata = () => {
        props.setProcessing(props.processing + "-3");

        FilesService.cleandata(folder, props.tb.accept)
            .then((response) => {
                initcto(response.data.message, "info", "-3", {});
                gfiles();
            })
            .catch((err) => {
                initcto(err.response.data ? err.response.data.message : "Impossible de vider la base de données ! Veuillez réessayer plus tard.", "danger", "-3", {});
            });
    }

    useLayoutEffect(() => {
        //On_Mount
        read(0);

        //On_Unmount
        //return () => { }
    }, []);

    return (
        <div className="form-group">
            <div className="dfac mt-1 mb-1">
                <Button variant="danger"
                    className="meds"
                    disabled={props.processing.indexOf("-3") != -1 || !files.length}
                    onClick={(e) => { e.currentTarget.blur(); props.setModalv([true, () => props.setModalv([]), "Alerte", "Êtes-vous sûr de bien vouloir supprimer tous les fichiers correspondant à cette section, et toutes leurs données ?", [<Button key="0" variant="secondary" onClick={() => props.setModalv([])}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); cleandata(); }}>Confirmer</Button>]]); }}
                    title="Supprimer tous les fichiers correspondant à cette section, et toutes leurs données."
                >{props.processing.indexOf("-3") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; </span> : null}
                    Vider Base de Données
                </Button>
                {props.tb.uploaddir && (
                    <div className="fdr"><label><Form.Control type="file" className="meds" ref={ref => inpt.current[2] = ref} disabled={props.processing.indexOf("-1") != -1} onChange={(event) => setSelectedfiles(event.target.files)} accept={props.tb.accept} title={props.tb.listn} multiple /*directory="" webkitdirectory=""*/ />
                    </label>
                        &nbsp;
                        <Button variant="success"
                            className="meds"
                            disabled={!selectedfiles || props.processing.indexOf("-1") != -1}
                            onClick={(e) => { e.currentTarget.blur(); upload(selectedfiles, 0); }}
                            title="Charger certains ou tous les fichiers d'un répertoire."
                        >
                            {props.processing.indexOf("-1") != -1 && progress < 100 ? <span><Spinner animation="border" size="sm" /> &nbsp; Chargement en cours...</span> : "Charger Répertoire"}
                        </Button>
                    </div>
                )}
            </div>
            {inpts ? <FormInputs ref={ref => inpt.current[0] = ref} setProcessing={props.setProcessing} initcto={props.initcto} setInputs={setInputs} inputs={inputs} inpts={inpts} reinit={props.tb.reinit} folder={folder} fltr={fltr} processing={props.processing} /> : null}
            <div className="dfac mt-1" style={{ marginBottom: 2 }}>
                {props.processing.indexOf("-1") != -1 && (
                    <div className="progress w100">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
                <label style={{ flex: 1 }}>
                    <Form.Control type="file" className="meds mw100" ref={ref => inpt.current[1] = ref} disabled={props.processing.indexOf("-1") != -1} onChange={(event) => setSelectedfile(event.target.files)} accept={props.tb.accept} title={props.tb.list1} />
                </label>
                <Button variant="info"
                    className="meds"
                    disabled={!selectedfile || props.processing.indexOf("-1") != -1}
                    onClick={(e) => { e.currentTarget.blur(); upload(selectedfile, ""); }}
                    title={props.title}
                >
                    {props.processing.indexOf("-1") != -1 && progress < 100 ? <span><Spinner animation="border" size="sm" /> &nbsp; Chargement en cours...</span> : props.tb.listc || "Charger Fichier"}
                </Button>
            </div>

            <div className="card mb-1">
                <div className="card-header">{props.tb.listr}</div>
                <InfiniteScroll className="list-group list-group-flush" dataLength={files.length} next={read} hasMore={hasmore} loader={<h6 className="center">Chargement en cours...</h6>} /*endMessage={<div className="alert bg-orange meds center">Plus de données à charger.</div>}*/>
                {files.length ? files.map((record, index) => {
                    let label = props.tb.label.map((val, idx) => record[val]); label = label.join(" ");
                    return <div className="list-group-item meds" key={index}>
                    <span className='textred cp' title={props.tb.listd}>{deltfile[record[props.tb.pk]] ? <Spinner animation="border" size="sm"></Spinner> : <BsTrash onClick={() => props.setModalv([true, () => props.setModalv([]), "Alerte", "Êtes-vous sûr de bien vouloir supprimer " + props.tb.hint + " : " + label + " ?", [<Button key="0" variant="secondary" onClick={() => props.setModalv([])}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); delet(record, label); }}>Confirmer</Button>]])} />} &nbsp; </span><a href={localStorage.getItem("url" + folder) + record.cnomfichier} target="_blank" /*rel="noopener noreferrer"*/ title={props.tb.hover}>{label}</a></div>;}) : <div className="list-group-item" style={{ padding: 0 }} key={0}><div className="alert alert-warning">Aucune donnée trouvée.</div></div>}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Files;
