import http from "../http";

const getUser = (cid) => {
    return http.get("/profil/" + cid);
};

const updateUser = (data) => {
    return http.put("/profil", data);
};

const changePassword = (data) => {
    return http.put("/passe/" + (data.rid ? "reinit" : "change"), data);
};

const resetPassword = (data) => {
    return http.put("/passe/reset", data);
};

const getRequete = (cid) => {
    return http.get("/passe/" + cid);
};

export default {
    getUser,
    updateUser,
    changePassword,
    resetPassword,
    getRequete
};
