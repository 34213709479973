import React, { useState, useLayoutEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { NavDropdown, Spinner } from "react-bootstrap";
import FiltersService from "../services/FiltersService";
import { Sicn } from "../helpers";

const Filters = forwardRef((props, ref) => {
  const ito = useRef();
  const [atabs, setAtabs] = useState([]);
  let sem = [], tri; if (props.aid) props.aid.map((v) => sem[v] = true);
  const [show, setShow] = useState(sem);
  sem = [{ title: "S1" }, { title: "S2" }]; tri = [{ title: "T1" }, { title: "T2" }, { title: "T3" }, { title: "T4" }];

  useImperativeHandle(ref, () => ({ setinitial, reinit, setextra, atabs }));

  const reinit = (cond, etab, idx) => {
    if (cond) {
      let trans = { ["0|||" + atabs[idx].title]: [atabs[idx].title] };
      props['set' + etab.charAt(0).toUpperCase() + etab.slice(1)]({ ...props[etab], c: trans });
      return trans;
    }
  }

  const setinitial = (data) => {
    if (data) {
      if (data.atabs) localStorage.setItem('fltr_' + props.folder + '_' + props.fltr, JSON.stringify(data.atabs)); else data.atabs = [];
    }
    else {
      data = { atabs: JSON.parse(localStorage.getItem('fltr_' + props.folder + '_' + props.fltr)) || [] };
    }
    setAtabs(data.atabs);
    props.setCtab && selecttab(props.tabid);
  }

  const setextra = (extra, etab) => {
    if (props[extra]) { if (etab) props[extra].map(fct => { fct.name.indexOf(etab) == -1 && fct((o) => ({ ...o, c: {} })); }); else props[extra].map(fct => fct((o) => ({ ...o, c: {} }))); }
  }

  const selecttab = (key) => {
    setextra("setCextra");
    let atab = props.atab[0].i;
    props['set' + atab.charAt(0).toUpperCase() + atab.slice(1)]({ ...props[atab], c: key });
    if(props.cbf) props.cbf.map(fct => fct(key));
  }

  const selectstab = (sidx, title, etab, type, qst) => {
    let s; setextra("setSextra", etab);
    if (qst) {
      s = show.slice(0); s[sidx] = false; setShow(s);
      s = props[qst[2]].c[qst[0] + "|||" + qst[1][0]] ? null : qst[1];
      if (qst[3] == "checkbox") {
        if (type == "radio" && Object.keys(props[etab].c).find((v) => v.split("|||")[0] != sidx)) s = { [qst[0] + "|||" + qst[1][0]]: s };
        else s = { ...props[qst[2]].c, [qst[0] + "|||" + qst[1][0]]: s };
      }
      else {
        s = { [qst[0] + "|||" + qst[1][0]]: s };
      }
      Object.keys(s).forEach((v) => !s[v] && delete s[v]);
      props['set' + qst[2].charAt(0).toUpperCase() + qst[2].slice(1)]({ ...props[qst[2]], c: s });
    }
    s = s && Object.keys(s).length ? title : props[etab].c[sidx + "|||" + title[0]] ? null : title;
    s = type == "checkbox" ? { ...props[etab].c, [sidx + "|||" + title[0]]: s } : { [sidx + "|||" + title[0]]: s };
    Object.keys(s).forEach((v) => !s[v] && delete s[v]);
    props['set' + etab.charAt(0).toUpperCase() + etab.slice(1)]({ ...props[etab], c: s });
  }

  const schk = (idx, sidx, title) => {
    return props[props.etab[idx].i].c[sidx + "|||" + title[0]] && props[props.etab[idx].i].c[sidx + "|||" + title[0]][0] == title[0] && (!title[1] || props[props.etab[idx].i].c[sidx + "|||" + title[0]][1] == title[1]) ? true : false;
  }

  const mchk = (idx, sidx, title) => {
    return props[props.etab[idx].a.i].c[sidx + "|||" + title[0]] && props[props.etab[idx].a.i].c[sidx + "|||" + title[0]][0] == title[0] && (!title[1] || props[props.etab[idx].a.i].c[sidx + "|||" + title[0]][1] == title[1]) ? true : false;
  }

  const ochk = (idx, oidx, title, i) => {
    return props[props.etab[idx].a.a[i].i].c[oidx + "|||" + title[0]] && props[props.etab[idx].a.a[i].i].c[oidx + "|||" + title[0]][0] == title[0] && (!title[1] || props[props.etab[idx].a.a[i].i].c[oidx + "|||" + title[0]][1] == title[1]) ? true : false;
  }

  const dsbl = (idx, title) => {
    let a = props[props.etab[idx].i].c, b, c, d = atabs[idx].length;
    if (a) {
      b = Object.keys(a); c = b.length;
      for (let i = 0; i < c; i++) {
        for (let j = 0; j < d; j++) {
          if (atabs[idx][j].title == a[b[i]][0] && atabs[idx][j].items0.find((key) => key.title == title))
            return false;
        }
      }
    }
    return true;
  }

  const toggle = (idx) => {
    let etab0 = props.etab[idx].a.a[0].i, etab1 = props.etab[idx].a.a[1].i, h0, h1;
    if (props[etab0].h) {
      h0 = null; h1 = 1;
    } else {
      h0 = 1; h1 = null;
    }
    props['set' + etab0.charAt(0).toUpperCase() + etab0.slice(1)]({ ...props[etab0], h: h0 });
    props['set' + etab1.charAt(0).toUpperCase() + etab1.slice(1)]({ ...props[etab1], h: h1 });
    setextra("setSextra");
  }

  const loader = (msg, cln) => {
    return props.processing.indexOf("0") != -1 ? <div className={"alert alert-info meds" + cln}><span><Spinner animation="border" size="md" /> &nbsp; Chargement...</span></div> : <center className={"alert alert-warning" + cln}>{msg}</center>;
  }

  const showtab = (idx, type) => {
    setextra("setCextra");
    let s = type == "checkbox" ? show.slice(0) : []; s[idx] = !s[idx];
    setShow(s);
  }

  useLayoutEffect(() => {
    //On_Mount
    if (!localStorage.getItem('fltr_' + props.folder + '_' + props.fltr)) {
      props.setProcessing(props.processing + "0");
      FiltersService.getFilter(props.folder, props.fltr, localStorage.getItem('transporteur'), props.ctrans, props.atab ? "&cii=" + props[props.atab[0].i].ci[props[props.atab[0].i].c] + "&ci0=" + props[props.etab[0].i].ci[0] + "&ci1=" + props[props.etab[1].i].ci[1] : "&sem=" + (sem.map((v) => v.title)).join(",") + "&tri=" + (tri.map((v) => v.title)).join(",")).then((response) => {
        setinitial(response.data);
        props.setProcessing(props.processing.replace("0", ""));
      }).catch((err) => { props.initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données initiales ! Veuillez recharger la page.", "danger", "0"); });
    }
    else {
      setinitial();
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  switch (props.fltr) {
    case 'cTheme':
      return (<Tabs defaultActiveKey={props.tabid} mountOnEnter={true} onSelect={(key) => selecttab(key)} id={"cfltr" + props.fltr}>
        {atabs.length ? atabs.map((val, idx) => <Tab key={idx} title={val.title} tabAttrs={{ title: val.title }} eventKey={idx}><div className="mb-2 smls nav nav-tabs fltri tabs">{val.items0.map((sval, sidx) => sval.items0 ?

          <NavDropdown key={sidx} className="nodd" active={schk(idx, sidx, [sval.title, val.title])} title={sval.title} renderMenuOnMount={true} show={show[sidx]} onToggle={(isOpen) => { let s = show.slice(0); s[sidx] = isOpen; setShow(s); }}>{sval.items0.map((qval, qidx) => <label key={qidx} title={qval.title} className="hil"><input type={props.etab[idx].a.t} onChange={e => { }} disabled={props.etab[idx].a.d} name={props.etab[idx].a.t + props.etab[idx].a.i + idx + sidx.toString() + props.fltr} checked={mchk(idx, qidx, [qval.title, sval.title])} onClick={() => selectstab(sidx, [sval.title, val.title], props.etab[idx].i, props.etab[idx].t, [qidx, [qval.title, sval.title], props.etab[idx].a.i, props.etab[idx].a.t])} /><div className="dropdown-item nlk smls cp">{qval.title}</div></label>)}</NavDropdown>

          : <label key={sidx} title={sval.title} className="nav-item hil"><input type={props.etab[idx].t} onChange={e => { }} disabled={props.etab[idx].d} name={props.etab[idx].t + props.etab[idx].i + idx + props.fltr} checked={schk(idx, sidx, [sval.title, val.title])} onClick={() => selectstab(sidx, [sval.title, val.title], props.etab[idx].i, props.etab[idx].t)} /><div className="nav-link nlk">{sval.title}</div></label>)}</div></Tab>)
          : loader("Aucun indicateur trouvé.")}
      </Tabs>);
    case 'cAnnee':
      return (<div id={"cfltr" + props.fltr} className="fltrdiv">
        {atabs.length ? atabs.map((val, idx) => <div key={idx} className="dfaic fdc"><div className="mt-1 smls nobg nav nav-tabs fltri mw67">{val.map((sval, sidx) => <label key={sidx} title={sval.title} className="nav-item hil but"><input type={props.etab[idx].t} onChange={e => { }} disabled={props.etab[idx].d} name={props.etab[idx].t + props.etab[idx].i + idx + props.fltr} checked={schk(idx, sidx, [sval.title])} onClick={() => selectstab(sidx, [sval.title], props.etab[idx].i, props.etab[idx].t)} /><div className="nav-link nlk">{sval.title}</div></label>)}</div>
          <div className="dfaic fdc mb-1 mw67"><center className="bgl brm2 mt-2 w100"><label>Cumul annuel</label></center>{[sem, tri].map((pval, pidx) => <div key={pidx} className="smls nobg nav nav-tabs fltri w100">{pval.map((oval, oidx) => <label key={oidx} title={oval.title} className="nav-item hil but"><input type={props.etab[idx].a.t} onChange={e => { }} disabled={dsbl(idx, oval.title)} name={props.etab[idx].a.t + props.etab[idx].a.i + idx + props.fltr} checked={mchk(idx, oidx, [oval.title])} onClick={() => selectstab(oidx, [oval.title], props.etab[idx].a.i, props.etab[idx].a.t)} /><div className="nav-link nlk">{oval.title}</div></label>)}</div>)}</div>
        </div>)
          : loader("Aucune période trouvée.")}
      </div>);
    case 'cMode':
      return (<div id={"cfltr" + props.fltr} className="fltrdiv" style={{ flex: 1 }}>
        {atabs.length ? <div className="lrgs nav nav-tabs fltri nofc">{atabs.map((val, idx) => (<div key={idx} className="dff1 fdc mt-1"><div className="pr"><label title={val.title} className="nav-item hil but blbl"><input type={props.etab[idx].t} onChange={e => { }} disabled={props.etab[idx].d} name={props.etab[idx].t + props.etab[idx].i + idx + props.fltr} checked={schk(idx, idx, [val.title])} onClick={() => selectstab(idx, [val.title], props.etab[idx].i, props.etab[idx].t)} /><div className="nav-link nlk f1jc bgm"><Sicn sra={[val.title]} alt={[val.title]} cln='licon twb' cmp={<>{val.title}</>} /></div></label>
          {localStorage.getItem('transporteur') != "SNCF" ? <div className="cbtn tgl cp" onClick={() => toggle(idx)} title={props[props.etab[idx].a.a[0].i].h ? "Affichage Unité : Basculer en Affichage Regroupement" : "Affichage Regroupement : Basculer en Affichage Unité"}>{props[props.etab[idx].a.a[0].i].h ? <><BsToggleOn /> Unité</> : <>Regroup <BsToggleOff /></>}</div> : null}
        </div>
          {val.items0.length ? <div className="mb0 fwnw smls nobg nav nav-tabs fltri nofc">{val.items0.map((sval, sidx) => <div key={sidx} className="dff1 fdc"><label title={sval.title} className="nav-item hil but mlbl"><input type={props.etab[idx].a.t} onChange={e => { }} disabled={localStorage.getItem('transporteur') != "SNCF" ? props.etab[idx].a.d : true} name={props.etab[idx].a.t + props.etab[idx].a.i + idx + props.fltr} checked={mchk(idx, sidx, [sval.title, val.title])} onClick={() => selectstab(sidx, [sval.title, val.title], props.etab[idx].a.i, props.etab[idx].a.t)} /><div className="nav-link nlk"><Sicn sra={[sval.title]} alt={[sval.title]} cln='micon' cmp={<>{sval.title}</>} /></div></label>
            {props[props.etab[idx].a.a[0].i].h ?
              sval.items1.length ? <div className="mb0 mb-1 nobg nav nav-tabs fltri">{sval.items1.map((oval, oidx) => <label key={oidx} title={oval.title} className="nav-item hil but slbl"><input type={props.etab[idx].a.a[1].t} onChange={e => { }} disabled={props.etab[idx].a.a[1].d} name={props.etab[idx].a.a[1].t + props.etab[idx].a.a[1].i + idx + props.fltr} checked={ochk(idx, oidx, [oval.title, sval.title, val.title], 1)} onClick={() => selectstab(oidx, [oval.title, sval.title, val.title], props.etab[idx].a.a[1].i, props.etab[idx].a.a[1].t)} /><div className="nav-link nlk"><Sicn sra={[oval.title]} alt={[oval.title]} cln='sicon' cmp={<>{oval.title}</>} /></div></label>)}</div> : null :
              sval.items0.length ? <div className="mb0 mb-1 nobg nav nav-tabs fltri">{sval.items0.map((oval, oidx) => <label key={oidx} title={oval.title} className="nav-item hil but slbl"><input type={props.etab[idx].a.a[0].t} onChange={e => { }} disabled={props.etab[idx].a.a[0].d} name={props.etab[idx].a.a[0].t + props.etab[idx].a.a[0].i + idx + props.fltr} checked={ochk(idx, oidx, [oval.title, sval.title, val.title], 0)} onClick={() => selectstab(oidx, [oval.title, sval.title, val.title], props.etab[idx].a.a[0].i, props.etab[idx].a.a[0].t)} /><div className="nav-link nlk"><Sicn sra={[oval.title]} alt={[oval.title]} cln='sicon' cmp={<>{oval.title}</>} /></div></label>)}</div> : null}
          </div>)}</div> : null}</div>))}</div>
          : loader("Aucun mode trouvé.")}
      </div>);
    case 'cPeriode':
      return (<div id={"cfltr" + props.fltr} className="fltrdiv">
        <div className="dfaic fdc">
          <div className="mt-1 ieds nobg nav nav-tabs fltri mw67">
            {props.etab.map((val, idx) => <label key={idx} title={val.l} className="nav-item hil but"><input type={val.t} onChange={e => { }} disabled={val.d} name={val.t + "0" + props.fltr} checked={!!show[idx]} onClick={() => showtab(idx, val.t)} /><div className="nav-link nlk">{val.l}</div></label>)}
          </div>
          {props.etab.map((val, idx) => show[idx] ? <div key={idx} className="mt-1 smls nobg nav nav-tabs fltri mw67">{atabs[idx] && atabs[idx].length ? atabs[idx].map((sval, sidx) => <label key={sidx} title={sval.title} className="nav-item hil but"><input type={val.a.t} onChange={e => { }} disabled={val.a.d} name={val.a.t + val.a.i + idx + props.fltr} checked={mchk(idx, sidx, [sval.title])} onClick={() => selectstab(sidx, [sval.title], val.a.i, val.a.t)} /><div className="nav-link nlk">{sval.title}</div></label>) : loader("Aucun filtre trouvé.", " w100")}</div> : null)}
        </div>
      </div>);
  }
});

export default Filters;
