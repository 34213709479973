import React, { useState, useLayoutEffect, useRef } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { BsFileLock2Fill } from "react-icons/bs";
import { Spinner, Form, Button } from "react-bootstrap";
import ProfilService from "../services/ProfilService";
import LoginService from "../services/LoginService";
import { InfoMsg, keyPress } from "../helpers";

const Passe = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const { cid } = useParams();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [cpwd, setCpwd] = useState("");
  const [confpwd, setConfpwd] = useState("");
  const [username, setUsername] = useState("");
  const [cemail, setCemail] = useState("");
  const [passe, setPasse] = useState(null);
  const [rpm, setRpm] = useState(null);

  let outi = localStorage.getItem('puti');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const changePassword = async () => {
    if (!cpwd || !confpwd) {
      initcto("Veuillez saisir une valeur pour le mot de passe et la confirmation du mot de passe !", "danger");
      return;
    }

    if (cpwd && !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/).test(cpwd)) {
      initcto("Le mot de passe doit comporter 8 caractères ou plus. Il doit contenir au moins un caractère spécial, au moins 1 chiffre, au moins 1 lettre majuscule et au moins 1 lettre minuscule. Il ne peut pas contenir d'espaces !", "danger");
      return;
    }

    if (confpwd != cpwd) {
      initcto("Le mot de passe et la confirmation du mot de passe doivent correspondre !", "danger");
      return;
    }

    setProcessing(processing + "1");
    ProfilService.changePassword({ cid: loggeduser.current.cid, cpwd: loggeduser.current.cpwd, pwd: cpwd, rid: cid })
      .then((response) => {
        if (response.data) {
          if (cid) {
            LoginService.findUsername(loggeduser.current.cusername, cpwd)
              .then((response) => {
                if (response.data && response.data.utilisateur) {
                  initcto(response.data.message, "info", "1", response.data, 1);
                }
                else {
                  initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
                }
              })
              .catch((err) => {
                initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
              });
          }
          else {
            initcto(response.data.message, "info", "1", response.data);
          }
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  }

  const resetPassword = async () => {
    if (!username/* || !cemail*/) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    //if (cemail && !(/\w+([\-\+\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*/).test(cemail)) {
    /*initcto("Veuillez saisir une adresse e-mail valide !", "danger");
    return;
  }*/

    setProcessing(processing + "1");
    ProfilService.resetPassword({ username, cemail })
      .then((response) => {
        if (response.data) {
          setUsername("");
          setCemail("");
          localStorage.removeItem('puti');
          window.dispatchEvent(new Event('storage'));
          loggeduser.current = {};
          initcto(response.data.message, "info", "1");
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (p) {
      let utilisateur = Object.assign(loggeduser.current, p.utilisateur, { changepasse: 1 });
      delete utilisateur.passe; localStorage.removeItem('puti');
      localStorage.setItem('outi', JSON.stringify(utilisateur)); localStorage.setItem('lastaction', Date.now()); window.dispatchEvent(new Event('storage'));
      loggeduser.current = utilisateur;
      setPasse(1);
    }
    if (a) setProcessing(processing.replace(a, ""));
  }

  const setmsg = (msg) => {
    localStorage.removeItem('puti');
    window.dispatchEvent(new Event('storage'));
    loggeduser.current = {};
    setRpm(msg);
    initcto(msg, "danger", "0");
  }

  useLayoutEffect(() => {
    //On_Mount
    if (cid) {
      setProcessing(processing + "0");
      ProfilService.getRequete(cid).then((response) => {
        if (response.data && response.data.utilisateur && response.data.requete) {
          let moment = require('moment');
          if (moment(response.data.requete.cdate, 'YYYY-MM-DD HH:mm:ss').isAfter(moment().subtract(6, 'hours'))) {
            if (!response.data.requete.cstatus) {
              response.data.utilisateur.passe = 1;
              localStorage.setItem('puti', JSON.stringify(response.data.utilisateur)); window.dispatchEvent(new Event('storage'));
              loggeduser.current = response.data.utilisateur;
              setProcessing(processing.replace("0", ""));
            }
            else {
              setmsg("Le lien a déjà été consommé. Il ne peut plus être utilisé. Merci d’en demander un nouveau.");
            }
          }
          else {
            setmsg("Pour des raisons de sécurité, le lien a expiré. Merci d’en demander un nouveau.");
          }
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "0");
        }
      }).catch((err) => { initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1"); });
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);//Mot de pass du user "admin" (cid=1) : $2b$05$ytmEK/npvSPLwwY9Bpr3POCSNMl.KfUJqvLhNv/oLFVQMNdO2iBLO

  return (
    localStorage.getItem('outi') || (loggeduser.current.cid && !loggeduser.current.passe) || passe ? <Navigate replace={true} to={{ pathname: '/login' }} /> : <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      {loggeduser.current.cid && loggeduser.current.passe ? <><div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsFileLock2Fill className='opl' />&nbsp; Réinitialisation du mot de passe</div></h2></div>
        <div className="dfaic dfdc jcs">
          {cid ? null : <h5 className="center mb-5 mt-3 alert alert-warning">Pour des raisons de sécurité, votre mot de passe doit être réinitialisé</h5>}
          <Form className="col-lg-6" autoComplete="off">
            <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
              <Form.Label className="smlbl">Nouveau Mot de passe *</Form.Label>
              <Form.Control type="text" className="mw100 picls" value={cpwd} onChange={(event) => setCpwd(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} autoFocus={!window.isMobile} />
            </Form.Group>
            <Form.Group className="mb-3 dfac fdc" controlId="inpt_3">
              <Form.Label className="smlbl">Confirmation du Mot de passe *</Form.Label>
              <Form.Control type="text" className="mw100 picls" value={confpwd} onChange={(event) => setConfpwd(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} />
            </Form.Group>
            <Form.Group className="mb-3 mt-5 dfac fdc">
              <Button ref={ref => inpt.current[10] = ref} variant="primary" style={{ flex: 1 }} onClick={changePassword} disabled={!cpwd || !confpwd || processing.indexOf("1") != -1}>
                {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Traitement en cours...</span> : "Soumettre"}
              </Button>
            </Form.Group>
          </Form>
        </div></> : <><div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsFileLock2Fill className='opl' />&nbsp; Modification ou réinitialisation du mot de passe</div></h2></div>
        {cid ? rpm ? <div className="dfaic dfdc jcs"><div className="mb-3 mt-3 dfac fdc center"><h5 className="center mb-5 mt-3 alert alert-warning">{rpm}</h5><Link to="/passe" title="Renvoyer la demande de réinitialisation de votre mot de passe">Renvoyer la demande de réinitialisation du mot de passe</Link></div></div> : null : <div className="dfaic dfdc jcs">
          <Form className="col-lg-6" autoComplete="off">
            <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
              <Form.Label className="smlbl">Identifiant *</Form.Label>
              <Form.Control type="text" className="mw100" value={username} onChange={(event) => setUsername(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" autoFocus={!window.isMobile} />
            </Form.Group>
            <Form.Group className="mb-3 dfac fdc" controlId="inpt_3">
              <Form.Label className="smlbl">Adresse e-mail</Form.Label>
              <Form.Control type="text" className="mw100" value={cemail} onChange={(event) => setCemail(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" />
            </Form.Group>
            <Form.Group className="mb-3 mt-5 dfac fdc">
              <Button ref={ref => inpt.current[10] = ref} variant="primary" style={{ flex: 1 }} onClick={resetPassword} disabled={!username || processing.indexOf("1") != -1}>
                {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Traitement en cours...</span> : "Soumettre"}
              </Button>
            </Form.Group>
          </Form>
        </div>}</>}
    </div>
  );
};

export default Passe;
