import React, { useState, useLayoutEffect, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { BsHammer, BsTrash, BsFillPersonLinesFill } from "react-icons/bs";
import { Tabs, Tab, Modal, Spinner, Button } from "react-bootstrap";
import AdminService from "../services/AdminService";
import Files from "./Files";
import { InfoMsg } from "../helpers";
import InfiniteScroll from "react-infinite-scroll-component";

const Admin = (props) => {
  const tabs = [{ header: "Fichiers Données", folder: "datafiles", accept: ".xls,.xlsx", table: "tfichier", pk: "cid", label: ["cnomfichier"], hint: "le fichier", utype: [1], rights: "crud", listc: "Charger Fichier", listr: "Liste de fichiers", listd: "Supprimer Fichier", list1: "Sélectionner un fichier", listn: "Sélectionner un ou plusieurs fichiers", title: "Gestion des fichiers de données", hover: "Télécharger le fichier" }/*, { header: "Fichiers Documentation", folder: "docfiles", accept: ".pdf,.ppt,.pptx,.htm,.html,.txt,.doc,.docx", table: "tfichier", pk: "cid", label: ["cnomfichier"], hint: "le fichier", utype: "*", rights: "crud", listc: "Charger Fichier", listr: "Liste de fichiers", listd: "Supprimer Fichier", list1: "Sélectionner un fichier", listn: "Sélectionner un ou plusieurs fichiers", title: "Gestion des fichiers de documentation", hover: "Télécharger le fichier", reinit: { ctitrefichier: ["Titre *", "", 1, undefined, { nosrch: 1 }], cdetailfichier: ["Description", "", 1, undefined, { nosrch: 1 }] } }*/], tabsl = tabs.length, tabsu = [{ header: "Comptes Utilisateurs", page: "/profil", table: "tutilisateur", pk: "cid", label: ["cfname", "clname"], hint: "l'utilisateur", utype: "*", rights: "crud", listc: "Ajouter Utilisateur", listr: "Liste d'utilisateurs", listd: "Supprimer Utilisateur", title: "Gestion des comptes d'utilisateurs", hover: "Voir le profil" }], tabsul = tabsu.length;

  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const location = useLocation();
  var { tabid } = location.state || {};
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [modalv, setModalv] = useState([]);
  const [dlist, setDlist] = useState(Array(tabsul).fill([]));
  const [order, setOrder] = useState(Array(tabsul).fill(['cid', 'DESC']));
  const [deltdlist, setDeltdlist] = useState(Array(tabsul).fill({}));
  const [hasmore, setHasmore] = useState(Array(tabsul).fill(true));

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  const delet = (i, record, label) => {
    setProcessing(props.processing + "-2");
    let f = deltdlist.slice(0); f[i] = { ...f[i], [record[tabsu[i].label]]: 1 };
    setDeltdlist(f);

    AdminService.delet(tabsu[i].table, tabsu[i].pk, record[tabsu[i].pk])
      .then((response) => {
        initcto(response.data.message, "info", "-2", record);
        f = dlist.slice(0); let tempv = f[i].findIndex(elm => elm[tabsu[i].pk] == record[tabsu[i].pk]);
        if (tempv != -1) {
          f[i].splice(tempv, 1);
          setDlist(f);
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Impossible de supprimer " + tabsu[i].hint + " : " + label, "danger", "-2", record);
      });
  }

  const read = (i, offset) => {
    if (offset === null || offset === undefined) offset = dlist[i].length;
    AdminService.read(tabsu[i].table, 20, offset, order[i]).then((response) => {
      let f;
      if (response.data && response.data.length) {
        f = dlist.slice(0); f[i] = offset ? dlist[i].concat(response.data) : response.data;
        setDlist(f);
      }
      else {
        if (!offset && dlist[i].length) {
          f = dlist.slice(0); f[i] = [];
          setDlist(f);
        }
        f = hasmore.slice(0); f[i] = false;
        setHasmore(f);
      }
    }).catch((err) => { });
  }

  const selecttab = (key) => {
    if (key.charAt(0) == "u") {
      read(key.substring(1), 0);
    }
  }

  useLayoutEffect(() => {
    //On_Mount
    if (tabid) selecttab(tabid);
    if (loggeduser.current.changepasse) {
      initcto("Votre mot de passe a été changé avec succès.", "info");
      setTimeout(() => {
        let utilisateur = Object.assign(loggeduser.current);
        delete utilisateur.changepasse;
        localStorage.setItem('outi', JSON.stringify(utilisateur)); window.dispatchEvent(new Event('storage'));
      }, 1000);
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  var tabsc = [];
  for (let i = 0; i < tabsl; i++) {
    if (tabs[i].utype == "*" || tabs[i].utype.find((v) => v == loggeduser.current.cutype)) {
      tabsc.push(<Tab key={"f" + i} title={tabs[i].header} tabAttrs={{ title: tabs[i].title }} eventKey={"f" + i}><Files key={i} tb={tabs[i]} setModalv={setModalv} initcto={initcto} setProcessing={setProcessing} processing={processing} /></Tab>);
      if (!tabid) tabid = "f" + i;
    }
  }

  for (let i = 0; i < tabsul; i++) {
    if ((tabsu[i].utype == "*" || tabsu[i].utype.find((v) => v == loggeduser.current.cutype)) && tabsu[i].rights.indexOf("r") != -1) {
      tabsc.push(<Tab key={"u" + i} title={tabsu[i].header} tabAttrs={{ title: tabsu[i].title }} eventKey={"u" + i}>
        {tabsu[i].rights.indexOf("c") != -1 ? <div className="dfac mt-1 mb-1" style={{ marginBottom: 2 }}><Button className="btn-info meds" as={Link} to={tabsu[i].page + "/0"}>{tabsu[i].listc}</Button></div> : null}
        <div className="card mb-1"><div className="card-header">{tabsu[i].listr}</div>
          <InfiniteScroll className="list-group list-group-flush" dataLength={dlist[i].length} next={() => read(i)} hasMore={hasmore[i]} loader={<h6 className="center">Chargement en cours...</h6>}>
            {dlist[i].length ? dlist[i].map((record, index) => {
              let label = tabsu[i].label.map((val, idx) => record[val]); label = label.join(" ");
              return <div className="list-group-item meds" key={index}>
                <span title={tabsu[i].listd}>{deltdlist[record[tabsu[i].pk]] ? <Spinner animation="border" size="sm"></Spinner> : record.cid == 1 || record.cutype == 1 ? <BsFillPersonLinesFill className='textblue' title="Profil Administrateur" /> : <BsTrash className='textred cp' onClick={() => setModalv([true, () => setModalv([]), "Alerte", "Êtes-vous sûr de bien vouloir supprimer " + tabsu[i].hint + " : " + label + " ?", [<Button key="0" variant="secondary" onClick={() => setModalv([])}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { setModalv([]); delet(i, record, label); }}>Confirmer</Button>]])} />} &nbsp; </span><Link to={tabsu[i].page + "/" + record[tabsu[i].pk]} title={tabsu[i].hover}>{label}</Link></div>;
            }) : <div className="list-group-item" style={{ padding: 0 }} key={0}><div className="alert alert-warning">Aucune donnée trouvée.</div></div>}
          </InfiniteScroll></div></Tab>);
      if (!tabid) tabid = "u" + i;
    }
  }

  return (
    loggeduser.current.cid && loggeduser.current.cutype < 5 ? <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-4", "-5"]} aproc={["-"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsHammer className='opl' />&nbsp; Accès Admin</div></h2></div>
      <Tabs defaultActiveKey={tabid || "f0"} mountOnEnter={true} id="admin" className="mb-2" onSelect={selecttab}>{tabsc}</Tabs>
      <Modal show={modalv[0]} onHide={modalv[1]} size="lg" centered>
        <Modal.Header closeButton><Modal.Title>{modalv[2]}</Modal.Title></Modal.Header>
        <Modal.Body>{modalv[3]}</Modal.Body>
        <Modal.Footer>{modalv[4]}</Modal.Footer>
      </Modal>
    </div> : <Navigate replace={true} to={{ pathname: '/logout/3' }} />
  );
};

export default Admin;
