import React, { useState, useLayoutEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { BsViewStacked } from "react-icons/bs";
import { InfoMsg, clearstore } from "../helpers";
import { Form, Button } from "react-bootstrap";

const Transporteur = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const location = useLocation();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");

  const getuser = () => {
    let outi = localStorage.getItem('outi');
    loggeduser.current = outi ? JSON.parse(outi) : {};
    return loggeduser.current.cgroupe ? loggeduser.current.cgroupe.split(",") : [];
  }
  const [pathname, setPathname] = useState(null);
  const cgroupe = getuser();

  const choosecontract = (transporteur) => {
    localStorage.setItem('transporteur', transporteur);
    clearstore('fltr_'); let from = location.state?.from; if(from && from.indexOf("admin") != -1 || from.indexOf("profil") != -1) from = "";
    setPathname(loggeduser.current.cutype < 5 ? from || '/resultats' : from || loggeduser.current.cpage || '/resultats');
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount
    if(cgroupe.length == 1) choosecontract(cgroupe[0]);

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    loggeduser.current.cid ? pathname ? <Navigate replace={true} to={pathname} /> : <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsViewStacked className='opl' />&nbsp; Transporteur</div></h2></div>
      <Form className="dfaic jcs" autoComplete="off">
        <Form.Group className="mb-3 mt-5 dfac fdc col-lg-6">
          {cgroupe.map((val, idx) => <Button key={idx} variant="primary" type="button" className="w100 gicon mb-3" onClick={() => choosecontract(val)}>{val.toUpperCase()}</Button>)}
        </Form.Group>
      </Form>
    </div> : <Navigate replace={true} to={{ pathname: '/logout/3' }} />
  );
};

export default Transporteur;
