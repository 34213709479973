import React, { useState, useLayoutEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { BsBoxArrowInRight } from "react-icons/bs";
import { Spinner, Form, Button } from "react-bootstrap";
import LoginService from "../services/LoginService";
import { InfoMsg, keyPress } from "../helpers";

const Login = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [passe, setPasse] = useState(null);

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const findUsername = () => {
    if (!username || !pwd) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    setProcessing(processing + "1");
    LoginService.findUsername(username, pwd)
      .then((response) => {
        if (response.data && response.data.utilisateur) {
          initcto(response.data.message, "info", "1", response.data);
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  };

  const mpo = () => {
    localStorage.removeItem('puti');
    window.dispatchEvent(new Event('storage'));
  };

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (p && p.utilisateur) {
      let moment = require('moment'), ls;
      if (moment(p.utilisateur.cldate, 'YYYY-MM-DD HH:mm:ss').isBefore(moment().subtract(6, 'months'))) {
        ls = 'puti';
        p.utilisateur.passe = 1;
      }
      else {
        ls = 'outi';
      }
      localStorage.setItem(ls, JSON.stringify(p.utilisateur)); localStorage.setItem('lastaction', Date.now()); window.dispatchEvent(new Event('storage'));
      loggeduser.current = p.utilisateur;
      if (p.utilisateur.passe) setPasse(1); else localStorage.removeItem('puti');
    }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    passe ? <Navigate replace={true} to={{ pathname: '/passe' }} /> : loggeduser.current.cid ? <Navigate replace={true} to={{ pathname: loggeduser.current.cpage || '/resultats' }} /> : <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsBoxArrowInRight className='opl' />&nbsp; Connexion</div></h2></div>
      <div className="dfaic dfdc jcs">
        <Form className="col-lg-6" autoComplete="off">
          <Form.Group className="mb-3 dfac fdc" controlId="inpt_1">
            <Form.Label>Identifiant *</Form.Label>
            <Form.Control type="text" className="mw100" value={username} onChange={(event) => setUsername(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" autoFocus={!window.isMobile} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
            <Form.Label>Mot de passe *</Form.Label>
            <Form.Control type="text" className="mw100 picls" value={pwd} onChange={(event) => setPwd(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" />
          </Form.Group>
          <Form.Group className="mb-3 mt-5 dfac fdc">
            <Button ref={ref => inpt.current[10] = ref} variant="primary" className="w100" onClick={findUsername} disabled={!username || !pwd || processing.indexOf("1") != -1}>
              {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Connexion en cours...</span> : "Connexion"}
            </Button>
          </Form.Group>
        </Form>
        <div className="mb-3 mt-3 dfac fdc">
          <Link to="/passe" onClick={mpo} title="Modifier ou réinitialiser votre mot de passe">Mot de passe oublié ?</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
