import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(Error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      this.props.FallbackCall && this.props.FallbackCall();
      return this.props.FallbackComponent ? this.props.FallbackComponent : <div className="alert alert-danger meds center w100">Sorry.. There was an error.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
