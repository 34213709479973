import React, { useState, useLayoutEffect, useRef } from "react";
import { Link, Navigate, useParams, useLocation } from "react-router-dom";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import { Spinner, Form, Button } from "react-bootstrap";
import ProfilService from "../services/ProfilService";
import IndexService from "../services/IndexService";
import { InfoMsg, keyPress } from "../helpers";

const Profil = (props) => {
  const ito = useRef(), loggeduser = useRef(), usr = useRef(), inpt = useRef([]);
  const { id } = useParams(), location = useLocation();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const iar = ['ttypeutilisateur', 'ttransporteur'];

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};
  usr.current = {};

  const setinitial = (data) => {
    if (data) {
      Object.keys(data).map((key) => data[key] && localStorage.setItem(key, JSON.stringify(data[key])));
    }
    else {
      data = {};
      iar.map((val) => data[val] = JSON.parse(localStorage.getItem(val)) || []);
    }
    setAdata({ ...adata, ...data });
    reinit();
  }

  const [updt, setUpdt] = useState(true);
  const [adata, setAdata] = useState({});
  const [myprof, setmyprof] = useState(false);
  const [cid, setCid] = useState("");
  const [cutype, setCutype] = useState("");
  const [ctrans, setCtrans] = useState("");
  const [cusername, setCusername] = useState("");
  const [cpwd, setCpwd] = useState("");
  const [confpwd, setConfpwd] = useState("");
  const [cfname, setCfname] = useState("");
  const [clname, setClname] = useState("");
  const [cemail, setCemail] = useState("");

  const updateUser = () => {
    if (!cutype || !ctrans || !cusername || !cfname || !clname || (!cid && !cpwd) || (cpwd && !confpwd)) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    if (cpwd) {
      if (cpwd && !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/).test(cpwd)) {
        initcto("Le mot de passe doit comporter 8 caractères ou plus. Il doit contenir au moins un caractère spécial, au moins 1 chiffre, au moins 1 lettre majuscule et au moins 1 lettre minuscule. Il ne peut pas contenir d'espaces !", "danger");
        return;
      }

      if (confpwd != cpwd) {
        initcto("Le mot de passe et la confirmation du mot de passe doivent correspondre !", "danger");
        return;
      }
    }

    if (cemail && !(/\w+([\-\+\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*/).test(cemail)) {
      initcto("Veuillez saisir une adresse e-mail valide !", "danger");
      return;
    }

    setProcessing(processing + "1");
    ProfilService.updateUser(Object.assign({ cutype, cusername, cfname, clname, cemail, ctrans }, cpwd ? { cpwd } : null, cid ? { cid } : null))
      .then((response) => {
        if (response.data && response.data.utilisateur) {
          initcto(response.data.message, "info", "1", response.data);
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  }

  const setuser = (upd) => {
    setCid(usr.current.cid);
    setCutype(usr.current.cutype);
    setCtrans(usr.current.ctrans);
    setCusername(usr.current.cusername);
    setCpwd("");
    setConfpwd("");
    setCfname(usr.current.cfname);
    setClname(usr.current.clname);
    setCemail(usr.current.cemail || "");
    setUpdt(upd);
  }

  const reinit = () => {
    let upd = id && parseInt(id) > 0, mprf = id == loggeduser.current.cid;
    if (myprof) {
      usr.current = loggeduser.current;
      setuser(upd);
    }
    else if (!upd) {
      usr.current = { cid: "", cutype: "", ctrans: "", cusername: "", cfname: "", clname: "", cemail: "" };
      setuser(upd);
    }
    else {
      setProcessing(processing + "0");
      ProfilService.getUser(id)
        .then((response) => {
          if (response.data && response.data.utilisateur) {
            usr.current = response.data.utilisateur;
            setuser(upd);
          }
          else {
            initcto("Utilisateur introuvale dans la base de données ! Veuillez réessayer.", "danger", "0");
          }
          setProcessing(processing.replace("0", ""));
        })
        .catch((err) => {
          initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez recharger la page ou réessayer plus tard.", "danger", "0");
        });
    }
    setmyprof(mprf);
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
    if (p && p.utilisateur) {
      if (myprof) {
        p.utilisateur = Object.assign(loggeduser.current, p.utilisateur);
        localStorage.setItem('outi', JSON.stringify(p.utilisateur)); window.dispatchEvent(new Event('storage'));
        loggeduser.current = p.utilisateur;
      }
    }
  }

  useLayoutEffect(() => {
    //On_Mount
    let tables = []; iar.map((val) => { if (!localStorage.getItem(val)) tables.push(val); });
    if (tables.length) {
      setProcessing(processing + "0");
      IndexService.getData(tables).then((response) => {
        setinitial(response.data.tables);
        setProcessing(processing.replace("0", ""));
      }).catch((err) => { initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données initiales ! Veuillez recharger la page ou réessayer plus tard.", "danger", "0"); });
    }
    else {
      setinitial();
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, [location]);

  return (
    loggeduser.current.cid ? <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsFillPersonBadgeFill className='opl' />&nbsp; {updt ? myprof ? "Mon Profil" : "Profil Utilisateur" : "Nouvel Utilisateur"}</div></h2></div>
      <Form className="dfac fr" autoComplete="off">
        <div className="row">
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_0">
            <Form.Label className="smlbl">Type Utilisateur *</Form.Label>
            <Form.Select className="mw100" value={cutype} onChange={(event) => setCutype(event.target.value)} disabled={updt}>
              <option value="">Veuillez sélectionner</option>
              {adata['ttypeutilisateur'] && adata['ttypeutilisateur'].map((val, idx) => <option key={idx} title={val.clabel} value={val.cid}>{val.clabel}</option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_0">
            <Form.Label className="smlbl">Transporteur *</Form.Label>
            <Form.Select className="mw100" value={ctrans} onChange={(event) => setCtrans(event.target.value)} disabled={updt}>
              <option value="">Veuillez sélectionner</option>
              {adata['ttransporteur'] && adata['ttransporteur'].map((val, idx) => <option key={idx} title={val.clabel} value={val.clabel}>{val.clabel}</option>)}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="row">
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_4">
            <Form.Label className="smlbl">Prénom *</Form.Label>
            <Form.Control type="text" className="mw100" value={cfname} onChange={(event) => setCfname(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" maxLength={50} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_5">
            <Form.Label className="smlbl">Nom *</Form.Label>
            <Form.Control type="text" className="mw100" value={clname} onChange={(event) => setClname(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" maxLength={50} />
          </Form.Group>
        </div>
        <div className="row">
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_2">
            <Form.Label className="smlbl">Mot de passe *</Form.Label>
            <Form.Control type="text" className="mw100 picls" value={cpwd} onChange={(event) => setCpwd(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_3">
            <Form.Label className="smlbl">Confirmation du Mot de passe *</Form.Label>
            <Form.Control type="text" className="mw100 picls" value={confpwd} onChange={(event) => setConfpwd(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} />
          </Form.Group>
        </div>
        <div className="row">
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_1">
            <Form.Label className="smlbl">Identifiant *</Form.Label>
            <Form.Control type="text" className="mw100" value={cusername} onChange={(event) => setCusername(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} disabled={updt} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc col-lg-5" controlId="inpt_6">
            <Form.Label className="smlbl">Adresse e-mail</Form.Label>
            <Form.Control type="text" className="mw100" value={cemail} onChange={(event) => setCemail(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={50} />
          </Form.Group>
        </div>
        <div className="dff1 jcs">
          <Form.Group className="mb-3 mt-4 dfac fdr">
            <Button ref={ref => inpt.current[10] = ref} variant="primary" style={{ flex: 1 }} onClick={updateUser} disabled={processing.indexOf("1") != -1}>
              {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Traitement en cours...</span> : "Soumettre"}
            </Button>
            <Button variant="warning" style={{ flex: 1 }} onClick={reinit} disabled={processing.indexOf("1") != -1}>
              Réinitialiser
            </Button>
            {loggeduser.current.cutype < 5 ? <Button variant="danger" style={{ flex: 1 }} className="center" as={Link} to="/admin" state={{ tabid: "u0" }}>
              Retour
            </Button> : null}
          </Form.Group>
        </div>
      </Form>
    </div> : <Navigate replace={true} to={{ pathname: '/logout/3' }} />
  );
};

export default Profil;
