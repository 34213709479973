import axios from "axios";

const api = axios.create({
  baseURL: "https://idfm.mv2enquetes.com:7444/api/v1",
  //baseURL: "http://localhost:8087/api/v1",
  headers: { "Content-type": "application/json" },
  withCredentials: true,
  maxContentLength: Infinity,
  maxBodyLength: Infinity
  //,timeout: 600000, //in ms => 10mn
});

export const setupInterceptors = (navigate) => {
  api.interceptors.request.use((config) => {
    let creqts = parseInt(localStorage.getItem('creqts') || 0);
    localStorage.setItem('creqts', creqts + 1);
    return config;
  }, undefined);

  api.interceptors.response.use((response) => {
    let creqts = parseInt(localStorage.getItem('creqts') || 0);
    localStorage.setItem('creqts', creqts - 1);
    return response;
  }, (err) => {
    let creqts = parseInt(localStorage.getItem('creqts') || 0);
    localStorage.setItem('creqts', creqts - 1);
    if (err.response.status == 401 || err.response.status == 403) {
      navigate("/logout/" + err.response.status, { replace: true });
    }
    return Promise.reject(err);
  });
}

export default api;