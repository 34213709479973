import React, { useState, useLayoutEffect, useRef } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { InfoMsg } from "../helpers";

const AideConnexion = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsInfoCircle className='opl' />&nbsp; Aide à la connexion</div></h2></div>
      <div className="dfaic dfdc jcs">
        <div className="mb-3 mt-3"><h4>Bienvenue sur le réseau IDFM</h4></div>
        <div className="mb-3 mt-3 dfac fdc">
          <img src={require('../images/idfmmv2aide.png')} alt="IDFM-MV2" className="bimg" />
        </div>
      </div>
    </div>
  );
};

export default AideConnexion;
