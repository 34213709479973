import React, { useState, useLayoutEffect, useRef, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { BsFillPieChartFill } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import ResultatsService from "../services/ResultatsService";
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, BarChart, Bar, LineChart, Line, PieChart, Pie, RadarChart, Radar, ScatterChart, Scatter, AreaChart, Area, Label, LabelList } from "recharts";
import Filters from "./Filters";
import { InfoMsg, ElipsText, ChartMenu, Actvsg, Sicn, ll, tlt, va, bgf, clr, clrs } from "../helpers";
import ErrorBoundary from "../helpers/ErrorBoundary";

const Resultats = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]), folder = "datafiles", chrts = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [data, setData] = useState();
  const [actvi, setActvi] = useState([]);
  const [expl, setExpl] = useState([]);
  const [gmsg, setGmsg] = useState("");
  const [ctab, setCtab] = useState({ c: 0, ci: ['-', 'non'] });
  const [stabs, setStabs] = useState({ c: {}, ci: ['oui', 'non'] });
  const [sanne, setSanne] = useState({ c: {} });
  const [speriod, setSperiod] = useState({ c: {} });
  const [strans, setStrans] = useState({ c: {} });
  const [smod, setSmod] = useState({ c: {} });
  const [sregroup, setSregroup] = useState({ c: {}, h: 1 });
  const [sunit, setSunit] = useState({ c: {} });
  const [squest, setSquest] = useState({ c: {} });
  const [par, setPar] = useState({ w: window.innerWidth, h: window.innerHeight, lrg0: 100, aut0: 300, lrg1: 500, aut1: 300, rckey: "0" });
  const gcat = { "Barres": [BarChart, Bar], "Histogramme": [BarChart, Bar], "Courbe": [LineChart, Line], "Secteurs": [PieChart, Pie], "Radar": [RadarChart, Radar], "Nuage de points": [ScatterChart, Scatter], "Aires": [AreaChart, Area] };
  const gt = (key) => key == 0 ? "Barres" : "Secteurs";
  const [gtype, setGtype] = useState(gt(ctab.c));
  const sgt = (key) => setGtype(gt(key));

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const mouseolg = (idx, index) => {
    let newArr = actvi.slice(0); newArr[idx] = index;
    setActvi(newArr);
  }

  const getdraw = useCallback((params) => {
    if (!Object.keys(params.sanne.c).length /*|| !Object.keys(params.speriod.c).length */ || (!Object.keys(params.smod.c).length && !Object.keys(params.sregroup.c).length && !Object.keys(params.sunit.c).length) || (params.ctab.c == 1 && !Object.keys(params.stabs.c).length)) {
      setGmsg("Veuillez sélectionner une valeur pour chacun des filtres ci-contre !");
      setData(null);
      return;
    } else {
      setGmsg("");
    }
    setProcessing(processing + "1");
    setPar({ ...par, rckey: params.ctab.c + params.stabs.c[Object.keys(params.stabs.c)[0]] + params.sanne.c[Object.keys(params.sanne.c)[0]] + params.speriod.c[Object.keys(params.speriod.c)[0]] + params.sunit.c[Object.keys(params.sunit.c)[0]] });
    ResultatsService.getDraw(folder, { transporteur: localStorage.getItem('transporteur'), calculindice0: params.ctab.ci[params.ctab.c], calculindice1: params.stabs.ci[params.ctab.c], ctab: params.ctab.c, stabs: params.stabs.c, sanne: params.sanne.c, speriod: params.speriod.c, smod: params.smod.c, sregroup: params.sregroup.c, sunit: params.sunit.c, squest: params.squest.c, globalperiod: window.globalperiod }).then((response) => {
      if (response.data) {
        setData(response.data.gdata);
        initcto("", "", "1");
        setActvi([]);
        setTimeout(() => inpt.current[2].scrollIntoView({ behavior: "smooth", block: "start" }), 100);
        setTimeout(() => document.querySelectorAll('.recharts-legend-item').forEach((li) => { if (li.querySelector('.recharts-legend-item-text').innerText == "") li.classList.add("dn"); }), 1000);
        setTimeout(() => document.querySelectorAll('.recharts-pie-label-text').forEach((li) => { if (li.querySelector('tspan').innerHTML == "") li.parentNode.classList.add("dn"); }), 3000);
      }
    }).catch((err) => {
      initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données du graphique ! Veuillez réessayer.", "danger", "1");
    });
  }, []);

  const drawg = () => {
    if (data) {
      if (data.length) {
        let data0, data1, ar, dy, bg, bs, stk = [], bar = [], tmp0, tmp1, lg, ss = Object.keys(stabs.c).length, larg1 = par.lrg1 / 6, ob = {}, oa = {}, ax = {}, ay = [], fnts = { fontSize: "calc(3px + 0.4vw + 0.4vh)", fill: "#000000" }, perc0 = 30, perc1 = 70, et = "", bp = "insideBottom", rf = (ref) => chrts.current[2] = ref, pos = (p) => gtype == "Secteurs" ? undefined : p, lp = (p) => ll(p, ss, larg1, fnts);

        if (data[0]) {
          data0 = [{ ...data[0], fill: '#55b2e1', text: data[0].valeur + '%' }, { label: '', valeur: 100 - data[0].valeur, fill: '#c5cace' }];
        }
        else {
          data0 = [{ label: '', valeur: 100, fill: '#c5cace', text: 'Aucune donnée trouvée.', ...(data[10] || { cTransporteur: localStorage.getItem('transporteur'), cAnnee: "", cPeriode: "", Cunite: "", quest: "" }) }];
        }

        switch (gtype) {
          case "Barres":
            oa.fill = '#ffffff';
            ob.layout = "vertical";
            bp = "insideBottomLeft";
            break;
          case "Secteurs":
            ax.label = lp;
            oa.fontWeight = '300';
            lg = <Legend key="-2" align="center" verticalAlign="bottom" iconType="rect" />;
            break;
          default:
            ob.margin = { top: 15, right: 30, bottom: 5, left: -20 };
        }

        if (ss && ctab.c == 0) {
          data1 = [];
          data[1].map((val, idx) => {
            if (tmp0 != val.label) {
              tmp0 = val.label;
              data1[data1.length] = { label: tmp0 };
            }
            if ((val.legend || "").toLowerCase() == 'effectif') data1[data1.length - 1].label += " (Nb Rep: " + val.valeur + ")"; else {
              data1[data1.length - 1][val.legend] = val.valeur;
              if (!stk.find((v) => v == val.legend)) stk.push(val.legend);
            }
          });

          if (data1.length) {
            bar.push(<Tooltip key={"t_" + expl[2]} cursor={{ fill: "rgba(0, 0, 0, .075)" }} content={(p) => tlt(p, 2, ss, expl)} />, <Legend key="-2" align="center" verticalAlign="bottom" iconType="rect" />);
            stk.map((v, i) => { tmp1 = clrs[v] || "#55b2e1"; bar.push(React.createElement(gcat[gtype][1], { key: "b_" + i, dataKey: v, type: "monotone", stackId: data0[0].Cunite, ...(gtype == "Aires" ? { fillOpacity: 1, stroke: tmp1, fill: "url(" + tmp1 + ")" } : { fill: tmp1 }), background: bgf, data: gtype == "Secteurs" ? data1 : null, ...ax }, <LabelList position={pos(bp)} valueAccessor={va(v)} style={{ ...fnts, ...oa }} />)); });
          }
        }
        else {
          if (data0[0].quest) et = " / " + data0[0].quest;
          //data1 = data[1];
          data1 = [];
          data[1].map((val, idx) => {
            if ((val.legend || "").toLowerCase() == 'effectif' || val.label.toLowerCase() == 'effectif') et += " (Nb Rep: " + val.valeur + ")"; else data1.push({ ...val, fill: gtype == "Secteurs" ? /*clrs[val.legend || val.label] || */clr[Math.floor(Math.random() * clr.length)] : "#55b2e1" });
          });
          if (data1.length) {
            bar.push(<Tooltip key={"t_" + expl[2]} cursor={false} content={(p) => tlt(p, 2, ss, expl)} />, React.createElement(gcat[gtype][1], { key: "b_0", dataKey: "valeur", nameKey: "legend", type: "monotone", ...(gtype == "Aires" ? { fillOpacity: 1, stroke: "#55b2e1", fill: "url(#55b2e1)" } : {}), background: { fill: '#c5cace' }, data: gtype == "Secteurs" ? data1 : null, radius: [5, 5, 5, 5], ...ax }, <LabelList position={pos(ctab.c == 0 ? bp : "center")} formatter={(valeur) => valeur + '%'} style={{ ...fnts, ...oa }} />), lg);
          }
        }

        if (window.innerWidth < window.innerHeight || data1.length > 12) {
          ar = Math.max((0.7 * window.innerWidth) / (0.7 * window.innerHeight), 0.4); //1 / 2
          bg = Math.max(0.005 * window.innerWidth + 0.007 * window.innerHeight, 14); //12
          bs = 13; //-1.1 * dy
          dy = -1.72 * bs; //-2.0 * bg
          if (data1.length < 4) ar += 0.5 / data1.length;
        }
        else {
          ar = Math.min((0.7 * window.innerWidth) / (0.7 * window.innerHeight), 1.8); //2 / 1
          bg = Math.max(0.005 * window.innerWidth + 0.007 * window.innerHeight, 14); //12
          bs = 15; //-1.2 * dy
          dy = -1.82 * bs; //-2.1 * bg
          if (data1.length < 4) ar += 1.7 / data1.length; else if (ctab.c == 1) ar += 3.0 / data1.length;
        }

        tmp0 = localStorage.getItem('transporteur') + "_" + data0[0].label + "_" + data0[0].Cunite + "_" + data0[0].cPeriode + "_" + data0[0].cAnnee;

        switch (gtype) {
          case "Barres":
            if (bar.length) bar.push(<XAxis key="x0" type="number" hide={true}></XAxis>, <YAxis key="y1" type="category" dataKey="label" tickLine={false} axisLine={false} mirror={true} tick={({ payload, textAnchor, verticalAnchor, fill, x, y, width, height }) => <ElipsText style={fnts} x={x} y={y} dx={-7} dy={dy} width={1.5 * par.lrg1} maxLines={2} textAnchor={textAnchor} verticalAnchor={"start"} payload={payload} />}></YAxis>);
            ay.push(<XAxis key="x1" type="number" hide={true}></XAxis>, <YAxis key="y0" type="category" dataKey="legend" tickLine={false} axisLine={false} mirror={true} tick={({ payload, textAnchor, verticalAnchor, fill, x, y, width, height }) => <ElipsText style={fnts} x={x} y={y} dx={-7} dy={dy} width={1.5 * par.lrg1} maxLines={2} textAnchor={textAnchor} verticalAnchor={"start"} payload={payload} />}></YAxis>);
            ob.maxBarSize = 1.5 * bs;
            break;
          case "Secteurs":
            break;
          default:
            if (bar.length) bar.push(<XAxis key="x0" type="category" dataKey="label" tickLine={false} axisLine={{ stroke: 'rgba(0,0,0,.12)' }} tick={data1.length < 4 ? <ElipsText style={fnts} /> : <ElipsText style={fnts} angle={-10} dx={0} dy={0} />} interval={0}></XAxis>, <YAxis key="y1" type="number" domain={[0, 100]} tickLine={false} axisLine={{ stroke: 'rgba(0,0,0,.12)' }} tick={fnts} unit="%"></YAxis>);
            ay.push(<XAxis key="x1" type="category" dataKey="legend" tickLine={false} axisLine={{ stroke: 'rgba(0,0,0,.12)' }} tick={data1.length < 4 ? <ElipsText style={fnts} /> : <ElipsText style={fnts} angle={-10} dx={0} dy={0} />} interval={0}></XAxis>, <YAxis key="y0" type="number" hide={true}></YAxis>);
        }

        return <div id="grapressec" ref={ref => chrts.current[0] = ref} className="dfdc dff1">
          <div className="df jcsa mt-1">
            <h4 title={data0[0].label + et}>{data0[0].label + et}</h4><div className="dfjc"><div className="btn bwbl smls nocp" title={data0[0].Cunite}><Sicn sra={[data0[0].Cunite]} alt={[data0[0].Cunite]} cln='sicon' cmp={<>{data0[0].Cunite}</>} /></div><div className="btn bwbl smls nocp" title={data0[0].cPeriode + " " + data0[0].cAnnee}>{data0[0].cPeriode + " " + data0[0].cAnnee}</div></div><ChartMenu expl={expl} setExpl={setExpl} idx={2} chrts={inpt.current[2]} cid={tmp0} tle="le graphique" hdr={1} gcat={gcat} gtype={gtype} setGtype={setGtype} /></div>
          <ErrorBoundary key={"rc0" + par.w + par.h + par.lrg0 + par.aut1 + par.rckey + gtype} FallbackComponent={<div className="alert alert-danger meds center w100">Impossible de dessiner le graphique avec la catégorie sélectionnée : {gtype}.</div>}/* FallbackCall={() => setTimeout(() => { inpt.current[4].setextra("setSextra"); setGtype(gcat["Barres"]); }, 2000)}*/><div className="dff1 jcs">
            {ctab.c == 0 ?
              <><ResponsiveContainer width={perc0 + "%"} height={"undefined"} aspect={1 / 1} className="df one"><PieChart ref={ref => chrts.current[1] = ref} margin={{ top: 15, right: -5, bottom: 5, left: -5 }}><Tooltip key={"t_" + expl[2]} content={(p) => tlt(p, 2, ss, expl)} /><Pie data={data0} dataKey="valeur" nameKey="label" activeIndex={0} onMouseOver={(dta, index) => mouseolg(0, index)} onMouseLeave={(dta, index) => mouseolg(0)} activeShape={Actvsg} innerRadius="65%"><Label value={data0[0].text} position="center" className="glbl" width={"100%"} /></Pie></PieChart></ResponsiveContainer>{bar.length ? <ResponsiveContainer width={perc1 + "%"} height={"undefined"} aspect={ar} className="df one">{React.createElement(gcat[gtype][0], { ref: rf, data: data1, margin: { top: 15, right: 15, bottom: 5, left: 10 }, barGap: 0.25 * bg, barCategoryGap: bg, ...ob }, <><defs>{clr.map((v) => <linearGradient key={v} id={v.replace('#', '')} x1="0" y1="0" x2="0" y2="1"><stop offset="5%" stopColor={v} stopOpacity={0.8} /><stop offset="95%" stopColor={v} stopOpacity={0.0} /></linearGradient>)}</defs><CartesianGrid horizontal={false} vertical={false} fill='#fff' />{bar}</>)}</ResponsiveContainer> : <div className="alert alert-info dfaic jcs center" style={{ width: perc1 - 2.5 + "%" }}>Données&nbsp;insuffisantes.</div>}</> :

              <>{bar.length ? <ResponsiveContainer width={"100%"} height={"undefined"} aspect={ar} className="df one">{React.createElement(gcat[gtype][0], { ref: rf, data: data1, margin: { top: 15, right: 10, bottom: 5, left: 10 }, barCategoryGap: 1.5 * bg, maxBarSize: 2.0 * bs, ...ob }, <><CartesianGrid horizontal={false} vertical={false} fill='#fff' />{ay}{bar}</>)}</ResponsiveContainer> : <div className="alert alert-info dfaic jcs center" style={{ width: "97.5%" }}>Données&nbsp;insuffisantes.</div>}</>}
          </div></ErrorBoundary></div>;
      }
      else {
        return <div className="alert alert-warning meds center w100">Données&nbsp;insuffisantes.</div>;
      }
    }
    else {
      return <div className="alert alert-warning meds center w100">{gmsg}</div>;
    }
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount & On_EachUpdate_InAnyElement_InDependencyArray
    getdraw({ ctab, stabs, sanne, speriod, strans, smod, sregroup, sunit, squest });
  }, [getdraw, ctab, stabs, sanne, speriod, strans, smod, sregroup, sunit, squest]);

  useLayoutEffect(() => {
    //On_Mount
    function updateSize() {
      if (inpt.current[1]) {
        let cs0 = window.getComputedStyle(inpt.current[1]), cs1 = window.getComputedStyle(inpt.current[2]);
        setPar(p => ({ ...p, w: window.innerWidth, h: window.innerHeight, lrg0: parseFloat(cs0.getPropertyValue('width')), aut0: parseFloat(cs0.getPropertyValue('height')), lrg1: parseFloat(cs1.getPropertyValue('width')), aut1: parseFloat(cs1.getPropertyValue('height')) }));
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    if (loggeduser.current.changepasse) {
      initcto("Votre mot de passe a été changé avec succès.", "info");
      setTimeout(() => {
        let utilisateur = Object.assign(loggeduser.current);
        delete utilisateur.changepasse;
        localStorage.setItem('outi', JSON.stringify(utilisateur)); window.dispatchEvent(new Event('storage'));
      }, 1000);
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); window.removeEventListener('resize', updateSize); }
  }, []);

  return (
    loggeduser.current.cid ? localStorage.getItem('transporteur') ? <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsFillPieChartFill className='opl' />&nbsp; Visualisation des résultats IGP - {localStorage.getItem('transporteur')}</div>{/*<ChartMenu expl={expl} setExpl={setExpl} idx={0} chrts={inpt.current[0]} cid={"Visualisation des résultats IGP - " + localStorage.getItem('transporteur')} tle="la page" cln="xsml" edp="edpp" />*/}</h2></div>
      <Filters ref={ref => inpt.current[3] = ref} setProcessing={setProcessing} initcto={initcto} atab={[{ i: "ctab", t: "radio" }]} ctab={ctab} setCtab={setCtab} etab={[{ i: "stabs", t: "radio" }, { i: "stabs", t: "radio", a: { i: "squest", t: "radio" } }]} stabs={stabs} setStabs={setStabs} squest={squest} setSquest={setSquest} setCextra={[setStabs, setSanne, setSperiod, setStrans, setSmod, setSregroup, setSunit, setSquest]} cbf={[sgt]} folder={folder} ctrans={loggeduser.current.ctrans} scrn="resultats" fltr="cTheme" tabid={ctab.c} processing={processing} />
      <div className="dfac fr fwnw mb-1">
        <div className="d33 dfdc" ref={ref => inpt.current[1] = ref}>
          <Filters ref={ref => inpt.current[4] = ref} setProcessing={setProcessing} initcto={initcto} etab={[{ i: "sanne", t: "radio", a: { i: "speriod", t: "radio" } }]} sanne={sanne} setSanne={setSanne} speriod={speriod} setSperiod={setSperiod} setSextra={[setSperiod]} folder={folder} ctrans={loggeduser.current.ctrans} scrn="resultats" fltr="cAnnee" processing={processing} />
          <Filters ref={ref => inpt.current[5] = ref} setProcessing={setProcessing} initcto={initcto} etab={[{ i: "strans", t: "radio", a: { i: "smod", t: "radio", a: [{ i: "sregroup", t: "radio" }, { i: "sunit", t: "radio" }] }, d: true }]} strans={strans} setStrans={setStrans} smod={smod} setSmod={setSmod} sregroup={sregroup} setSregroup={setSregroup} sunit={sunit} setSunit={setSunit} setSextra={[setStrans, setSmod, setSregroup, setSunit]} folder={folder} ctrans={loggeduser.current.ctrans} scrn="resultats" fltr="cMode" processing={processing} />
        </div>
        <div id="grapresdiv" className="d67 dfdc" ref={ref => inpt.current[2] = ref}>
          {processing.indexOf("1") != -1 ? <div className={"alert alert-info meds"}><span><Spinner animation="border" size="md" /> &nbsp; Chargement...</span></div> : <div className="dfac brdb" style={{ justifyContent: "center", height: "100%" }}>{drawg()}</div>}
        </div>
      </div>
    </div> : <Navigate replace={true} to={{ pathname: '/transporteur' }} state={{ from: '/resultats' }} /> : <Navigate replace={true} to={{ pathname: '/logout/3' }} />
  );
};

export default Resultats;
