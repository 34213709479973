import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { BsAt } from "react-icons/bs";
import { Spinner, Form, Button } from "react-bootstrap";
import ContactService from "../services/ContactService";
import { Editor } from '@tinymce/tinymce-react';
import { InfoMsg, keyPress } from "../helpers";

const Contact = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("-5");
  const [lmsg, setLmsg] = useState(0);
  const sizeLimit = 1000;

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const [email, setEmail] = useState(loggeduser.current.cemail || "");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [fs, setFs] = useState(["15px", "100px"]);

  const sendcontact = () => {
    if (!email || !subject || !msg) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    if (!(/\w+([\-\+\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*/).test(email)) {
      initcto("Veuillez saisir une adresse e-mail valide !", "danger");
      return;
    }

    setProcessing(processing + "1");
    ContactService.sendContact(email, subject, msg)
      .then((response) => {
        initcto(response.data.message, "info", "1");
        setEmail("");
        setSubject("");
        setMsg("");
        setLmsg(0);
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  }

  const changemsg = (value, editor) => {
    const l = editor.getContent({ format: 'text' }).length;
    if (l <= sizeLimit) {
      setMsg(value);
      setLmsg(l);
    }
  }

  const beforeaddundo = (evt, editor) => {
    const l = editor.getContent({ format: 'text' }).length;
    if (l > sizeLimit) {
      evt.preventDefault();
    }
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount
    let cs = window.getComputedStyle(inpt.current[2]);
    setFs([cs.getPropertyValue('font-size'), cs.getPropertyValue('width')]);

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    <div ref={ref => inpt.current[0] = ref} className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="pgtl dfaic jcsb"><div><BsAt className='opl' />&nbsp; Contact</div></h2></div>
      <div className="dfaic dfdc jcs">
        <Form className="col-lg-6" autoComplete="off">
          <Form.Group className="mb-3 dfac fdc" controlId="inpt_1">
            <Form.Label>Adresse e-mail *</Form.Label>
            <Form.Control type="text" className="mw100" value={email} onChange={(event) => setEmail(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" autoFocus={!window.isMobile} ref={ref => inpt.current[2] = ref} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
            <Form.Label>Objet de votre message *</Form.Label>
            <Form.Control type="text" className="mw100" value={subject} onChange={(event) => setSubject(event.target.value)} onKeyDown={(e) => keyPress(e, () => inpt.current[10].click())} maxLength={300} />
          </Form.Group>
          <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
            <Form.Label>Votre message *</Form.Label>
            <div key={fs[0]} className="edtr mw100" style={{ width: fs[1], height: "calc(50px + 20vh)" }}>
              <Editor /*apiKey="8mq1yy151oz1kjwlz6h19mar8z2kvnuw6pieo0kxm2b36n07"*/ tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"} value={msg} onEditorChange={changemsg} onBeforeAddUndo={beforeaddundo} onInit={(evt, ref) => { inpt.current[1] = ref; setProcessing(processing.replace("-5", "")); }} init={{ height: "calc(50px + 20vh)", max_width: "100%", menubar: false/*, statusbar: false*/, branding: false, plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'], toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help', toolbar_mode: 'floating', content_style: 'body { font-family:"Gill Sans","Gill Sans MT","Calibri","sans-serif";font-size:' + fs[0] + ';color: rgb(51, 51, 51);font-weight: 300; } p { margin:5px 0; }' }} />
              <p className="opl meds">Restant : {sizeLimit - lmsg} caractère(s).</p>
              {processing.indexOf("-5") != -1 ? <div className="loadlyr form-control center mw100"><div className="sublyr"><span><Spinner animation="border" /> &nbsp; Chargement...</span></div></div> : null}
            </div>
          </Form.Group>
          <Form.Group className="mb-3 mt-5 dfac fdc">
            <Button ref={ref => inpt.current[10] = ref} variant="primary" className="w100" onClick={sendcontact} disabled={!email || !subject || !msg || processing.indexOf("1") != -1}>
              {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Envoi en cours...</span> : "Envoyer"}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default Contact;
