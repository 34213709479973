import React, { useState, useLayoutEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Form, Button } from "react-bootstrap";
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import InputsService from "../services/InputsService";

const FormInputs = forwardRef((props, ref) => {
  const ito = useRef(), inpt = useRef([]), folder = props.folder || "datafiles", fltr = props.fltr || "v";
  const [aanneev, setAanneev] = useState([]);
  const [atypev, setAtypev] = useState([]);
  const [anomv, setAnomv] = useState([]);
  const [athemev, setAthemev] = useState([]);
  const [athemav, setAthemav] = useState({});
  const [atypes, setAtypes] = useState([]);
  const [anoms, setAnoms] = useState([]);
  const [afreqv, setAfreqv] = useState([]);
  const [canneevague, setCanneevague] = useState(props.inpts.canneevague ? "" : undefined);
  const [ctypevague, setCtypevague] = useState(props.inpts.ctypevague ? "" : undefined);
  const [cnomvague, setCnomvague] = useState(props.inpts.cnomvague ? "" : undefined);
  const [ctheme, setCtheme] = useState(props.inpts.ctheme ? "" : undefined);
  const [ctypesite, setCtypesite] = useState(props.inpts.ctypesite ? "" : undefined);
  const [cnomsite, setCnomsite] = useState(props.inpts.cnomsite ? "" : undefined);
  const [cfrequence, setCfrequence] = useState(props.inpts.cfrequence ? "" : undefined);
  const [cthematique, setCthematique] = useState(props.inpts.cthematique ? "" : undefined);
  const [largg, setLargg] = useState(props.inpts.largg ? "" : undefined);
  const [auteg, setAuteg] = useState(props.inpts.auteg ? "" : undefined);
  const [ctitrefichier, setCtitrefichier] = useState(props.inpts.ctitrefichier ? "" : undefined);
  const [cdetailfichier, setCdetailfichier] = useState(props.inpts.cdetailfichier ? "" : undefined);
  const [searcht, setSearcht] = useState(props.inpts.searcht ? "" : undefined);
  const [hiden, setHiden] = useState();
  const [srch, setSrch] = useState();
  var nbf = 0; Object.keys(props.inpts).map((val, idx) => props.inpts[val][2] && nbf++);

  const reinitg = (ctid) => {
    if (ctid) ctid.cthematique = "";
    sall("cthematique", "", setCthematique, undefined, undefined, 1);
    if (props.setData && props.inpts.data) props.setData(props.inpts.data[1]);
    inpt.current[7]?.scrollTo(0, 0);
  }
  useImperativeHandle(ref, () => ({ reinitg, setinitial, setHiden }));

  const changeCtypevague = (stypev, satypev, init) => {
    let snomv, ctid = [];
    if (stypev === undefined) stypev = ctypevague;
    if (!satypev) satypev = atypev; if (!satypev.length) satypev = [{}];
    if (stypev) snomv = satypev.find((val, idx) => val.ctypevague == stypev); if (!snomv) snomv = satypev; else snomv = [snomv];//snomv = satypev[0];
    if (snomv) {
      snomv.map((sval, sidx) => {
        if (sval.cnomvague) ctid = ctid.concat(sval.cnomvague.split("|||"));
      });
    }
    setAnomv(ctid);

    snomv = ""; ctid = { cnomvague: snomv }; //snomv = snomv[0];
    if (init) reinitg(ctid); setCnomvague(snomv); sall("ctypevague", stypev, setCtypevague, undefined, ctid);
    return snomv;
  }

  const changeCtypesite = (stypes, satypes, init) => {
    let snoms, ctid = [];
    if (stypes === undefined) stypes = ctypesite;
    if (!satypes) satypes = atypes; if (!satypes.length) satypes = [{}];
    if (stypes) snoms = satypes.find((val, idx) => val.ctypesite == stypes); if (!snoms) snoms = satypes; else snoms = [snoms];//snoms = satypes[0];
    if (snoms) {
      snoms.map((sval, sidx) => {
        if (sval.cnomsite) sval.cnomsite.split("|||").map((aval, aidx) => { if (!ctid.find((tval, tidx) => tval == aval)) if (aval.toLowerCase() == "total region") ctid.unshift(aval); else ctid.push(aval); });
      });
    }
    setAnoms(ctid);

    snoms = ""; ctid = { cnomsite: snoms }; //snoms = snoms[0];
    if (init) reinitg(ctid); setCnomsite(snoms); sall("ctypesite", stypes, setCtypesite, undefined, ctid);
    return snoms;
  }

  const changeCtheme = (sthemev, sathemev, init) => {
    let sthemav, ctid = {}, athema = JSON.parse(localStorage.getItem('athema' + folder + fltr)), tarr, tempa, tempb;
    if (sthemev === undefined) sthemev = ctheme; if (!athema) athema = [];
    if (sthemev && athemev && athemev.length) {
      if (!sathemev) sathemev = athemev; if (!sathemev.length) sathemev = [{}];
      sthemav = sathemev.find((val, idx) => val.cid == sthemev); //sthemav = sathemev[0];
    }
    tarr = sthemav && sthemav.ctid ? sthemav.ctid.split(",") : athema.map((t) => t.cid);
    athema.map((tematique, idx) => {
      tempb = tarr.find((t) => t == tematique.cid);
      if (tematique.cpid) {
        if (!ctid[tematique.cpid]) {
          if (tematique.clabel2) { tempa = tematique.clabel2; } else { tempa = athema.find((tval, tidx) => tval.cid == tematique.cpid); tempa = tempa ? tempa.clabel : tematique.clabel; }
          ctid[tematique.cpid] = { clabel: tempa, children: tempb ? [] : undefined };
        }
        if (tempb) {
          if (!ctid[tematique.cpid].children) ctid[tematique.cpid].children = []; if (!ctid[tematique.cpid].children.find((tval, tidx) => tval.cid == tematique.cid)) ctid[tematique.cpid].children.push({ cid: tematique.cid, clabel: tematique.clabel });
        }
      }
      else {
        ctid[tematique.cid] = { clabel: tematique.clabel2 || tematique.clabel, children: tempb ? [] : undefined };
      }
    });
    setAthemav(ctid);

    sthemav = ""; ctid = { cthematique: sthemav }; //tematique = Object.keys(ctid); if (tematique.length) { tempa = ctid[tematique[0]]; if (tempa) { tempa = tempa.children; sthemav = tempa.length ? tempa[0].cid : tematique[0]; } }
    if (init) reinitg(ctid); setCthematique(sthemav); sall("ctheme", sthemev, setCtheme, undefined, ctid);
    return sthemav;
  }

  const setinitial = (data, init) => {
    let inpts = { ...props.inputs };
    if (data) {
      data.aanneev && localStorage.setItem('aannee' + folder + fltr, JSON.stringify(data.aanneev));
      data.atypev && localStorage.setItem('atype' + folder + fltr, JSON.stringify(data.atypev));
      data.athemev && localStorage.setItem('atheme' + folder + fltr, JSON.stringify(data.athemev));
      data.athema && localStorage.setItem('athema' + folder + fltr, JSON.stringify(data.athema));
      data.atypes && localStorage.setItem('atypes' + folder + fltr, JSON.stringify(data.atypes));
      data.afreqv && localStorage.setItem('afreq' + folder + fltr, JSON.stringify(data.afreqv));
      localStorage.setItem(folder + fltr, "1");
    }
    else {
      data = { aanneev: JSON.parse(localStorage.getItem('aannee' + folder + fltr)) || [], atypev: JSON.parse(localStorage.getItem('atype' + folder + fltr)) || [], athemev: JSON.parse(localStorage.getItem('atheme' + folder + fltr)) || [], atypes: JSON.parse(localStorage.getItem('atypes' + folder + fltr)) || [], afreqv: JSON.parse(localStorage.getItem('afreq' + folder + fltr)) || [] };
    }
    /*if (data.aanneev[0]) setCanneevague(data.aanneev[0].canneevague);
    if (data.atypev[0]) { setCtypevague(data.atypev[0].ctypevague); abc = data.atypev[0].ctypevague; } else abc = ""; changeCtypevague(abc, data.atypev);
    if (data.athemev[0]) { setCtheme(data.athemev[0].cid); abc = data.athemev[0].cid; } else abc = ""; changeCtheme(abc, data.athemev);
    if (data.atypes[0]) { setCtypesite(data.atypes[0].ctypesite); abc = data.atypes[0].ctypesite; } else abc = ""; changeCtypesite(abc, data.atypes);
    if (data.afreqv[0]) setCfrequence(data.afreqv[0].cfrequence);*/
    if (props.inpts.canneevague) { setCanneevague(props.inpts.canneevague[1]); inpts.canneevague = props.inpts.canneevague[1]; }
    if (props.inpts.ctypevague) { setCtypevague(props.inpts.ctypevague[1]); inpts.ctypevague = props.inpts.ctypevague[1]; inpts.cnomvague = changeCtypevague(props.inpts.ctypevague[1], data.atypev); }
    if (props.inpts.ctheme) { setCtheme(props.inpts.ctheme[1]); inpts.ctheme = props.inpts.ctheme[1]; inpts.cthematique = changeCtheme(props.inpts.ctheme[1], data.athemev); }
    else if (props.inpts.cthematique) { inpts.cthematique = changeCtheme(); }
    if (props.inpts.ctypesite) { setCtypesite(props.inpts.ctypesite[1]); inpts.ctypesite = props.inpts.ctypesite[1]; inpts.cnomsite = changeCtypesite(props.inpts.ctypesite[1], data.atypes, init); }
    if (props.inpts.cfrequence) { setCfrequence(props.inpts.cfrequence[1]); inpts.cfrequence = props.inpts.cfrequence[1]; }
    if (props.inpts.largg) { setLargg(props.inpts.largg[1]); inpts.largg = props.inpts.largg[1]; }
    if (props.inpts.auteg) { setAuteg(props.inpts.auteg[1]); inpts.auteg = props.inpts.auteg[1]; }
    if (props.inpts.ctitrefichier) { setCtitrefichier(props.inpts.ctitrefichier[1]); inpts.ctitrefichier = props.inpts.ctitrefichier[1]; }
    if (props.inpts.cdetailfichier) { setCdetailfichier(props.inpts.cdetailfichier[1]); inpts.cdetailfichier = props.inpts.cdetailfichier[1]; }
    if (props.inpts.searcht) { setSearcht(props.inpts.searcht[1]); inpts.searcht = props.inpts.searcht[1]; }
    props.setInputs(inpts);
    setAanneev(data.aanneev);
    setAtypev(data.atypev);
    setAthemev(data.athemev);
    setAtypes(data.atypes);
    setAfreqv(data.afreqv);
  }

  const sall = (key, val, clb, trf, exp, noa, bnc) => {
    if (!noa && props.inpts[key] && props.inpts[key][4] && props.inpts[key][4].alrt) localStorage.setItem("alrt", key); else localStorage.removeItem("alrt");
    clb(trf ? trf(val) : val);
    if (bnc && props.inpts[key] && (!props.inpts[key][4] || !props.inpts[key][4].nosrch)) { clearTimeout(ito.current); setSrch(bnc); ito.current = setTimeout(() => { props.setInputs({ ...props.inputs, [key]: val, ...exp }); setSrch(); }, 500); } else { props.setInputs({ ...props.inputs, [key]: val, ...exp }); }
  }

  useLayoutEffect(() => {
    //On_Mount
    if (!localStorage.getItem(folder + fltr)) {
      props.setProcessing(props.processing + "0");
      InputsService.getData(folder, fltr).then((response) => {
        setinitial(response.data);
        props.setProcessing(props.processing.replace("0", ""));
      }).catch((err) => { props.initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données initiales ! Veuillez recharger la page.", "danger", "0"); });
    }
    else {
      setinitial();
    }

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    <Form className={"dfac fdc meds w100" + (nbf ? "" : " dn")}>
      {hiden ? <BsCaretDownFill className='opl shicn cp' title='Afficher les filtres' onClick={() => setHiden()} /> : <BsCaretUpFill className='opl shicn cp' title='Masquer les filtres' onClick={() => setHiden(true)} />}
      <div className="trcont w100">
        <div className="dfac trans trdiv aifs" style={hiden ? { position: "absolute", top: -30, width: 0, height: 0, opacity: 0, zIndex: -1, overflow: "hidden" } : null}>
          {props.inpts.ctypevague && props.inpts.ctypevague[2] ? <fieldset className={props.inpts.ctypevague[4].nof}><legend>{props.inpts.ctypevague[0]}</legend>
            <Form.Group controlId="ctypevague">
              <Form.Select className="meds" value={ctypevague} onChange={(event) => { changeCtypevague(event.target.value, undefined, props.inpts.cnomvague); }} ref={ref => inpt.current[1] = ref}>
                <option value="">Veuillez sélectionner</option>
                {atypev ? atypev.map((val, idx) => (
                  <option key={idx} title={val.ctypevague} value={val.ctypevague}>{val.ctypevague}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.canneevague && props.inpts.canneevague[2] ? <fieldset className={props.inpts.canneevague[4].nof}><legend>{props.inpts.canneevague[0]}</legend>
            <Form.Group controlId="canneevague">
              <Form.Select className="meds" value={canneevague} onChange={(event) => { sall("canneevague", event.target.value, setCanneevague); }} ref={ref => inpt.current[11] = ref}>
                <option value="">Veuillez sélectionner</option>
                {aanneev ? aanneev.map((val, idx) => (
                  <option key={idx} title={val.canneevague} value={val.canneevague}>{val.canneevague}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.cnomvague && props.inpts.cnomvague[2] ? <fieldset className={props.inpts.cnomvague[4].nof}><legend>{props.inpts.cnomvague[0]}</legend>
            <Form.Group controlId="cnomvague">
              <Form.Select className="meds" value={cnomvague} onChange={(event) => { sall("cnomvague", event.target.value, setCnomvague); }} ref={ref => inpt.current[2] = ref}>
                <option value="">Veuillez sélectionner</option>
                {anomv ? anomv.map((val, idx) => (
                  <option key={idx} title={val} value={val}>{val}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.ctheme && props.inpts.ctheme[2] ? <fieldset className={props.inpts.ctheme[4].nof}><legend>{props.inpts.ctheme[0]}</legend>
            <Form.Group controlId="ctheme">
              <Form.Select className="meds" value={ctheme} onChange={(event) => { changeCtheme(event.target.value, undefined, 1); }} ref={ref => inpt.current[3] = ref}>
                <option value="">Veuillez sélectionner</option>
                {athemev ? athemev.map((val, idx) => (
                  <option key={idx} title={val.cid} value={val.cid}>{val.cid}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.ctypesite && props.inpts.ctypesite[2] ? <fieldset className={props.inpts.ctypesite[4].nof}><legend>{props.inpts.ctypesite[0]}</legend>
            <Form.Group controlId="ctypesite">
              <Form.Select className="meds" value={ctypesite} onChange={(event) => { changeCtypesite(event.target.value, undefined, props.inpts.cnomsite); }} ref={ref => inpt.current[4] = ref}>
                <option value="">Veuillez sélectionner</option>
                {atypes ? atypes.map((val, idx) => (
                  <option key={idx} title={val.ctypesite} value={val.ctypesite}>{val.ctypesite}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.cnomsite && props.inpts.cnomsite[2] ? <fieldset className={props.inpts.cnomsite[4].nof}><legend>{props.inpts.cnomsite[0]}</legend>
            <Form.Group controlId="cnomsite">
              <Form.Select className="meds" value={cnomsite} onChange={(event) => { sall("cnomsite", event.target.value, setCnomsite); }} ref={ref => inpt.current[5] = ref}>
                <option value="">Veuillez sélectionner</option>
                {anoms ? anoms.map((val, idx) => (
                  <option key={idx} title={val} value={val}>{val}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.cfrequence && props.inpts.cfrequence[2] ? <fieldset className={props.inpts.cfrequence[4].nof}><legend>{props.inpts.cfrequence[0]}</legend>
            <Form.Group controlId="cfrequence">
              <Form.Select className="meds" value={cfrequence} onChange={(event) => { sall("cfrequence", event.target.value, setCfrequence); }} ref={ref => inpt.current[6] = ref}>
                <option value="">Veuillez sélectionner</option>
                {afreqv ? afreqv.map((val, idx) => (
                  <option key={idx} title={val.cfrequence} value={val.cfrequence}>{val.cfrequence}</option>)) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.cthematique && props.inpts.cthematique[2] ? <fieldset className={props.inpts.cthematique[4].nof}><legend>{props.inpts.cthematique[0]}</legend>
            <Form.Group controlId="cthematique">
              <Form.Select className="meds" value={cthematique} onChange={(event) => { sall("cthematique", event.target.value, setCthematique); }} ref={ref => inpt.current[7] = ref} htmlSize="5">
                <option value="">Veuillez sélectionner</option>
                {athemav ? Object.keys(athemav).map((val, idx) => { if (athemav[val].children) { let ctid = [], cld = athemav[val].children.slice(0), acld = []; if (!cld.length) cld = [{ cid: val, clabel: athemav[val].clabel }]; cld.map((cval, cidx) => acld.push(<option key={cval.cid + cidx} title={cval.clabel} value={cval.cid}>{'→ '}{cval.clabel}</option>)); ctid.push(<optgroup key={val} title={athemav[val].clabel} label={athemav[val].clabel}>{acld}</optgroup>); return ctid; } }) : null}
              </Form.Select>
            </Form.Group>
          </fieldset> : null}
          {props.inpts.ctitrefichier && props.inpts.ctitrefichier[2] ? <fieldset className={props.inpts.ctitrefichier[4].nof}><legend>{props.inpts.ctitrefichier[0]}</legend>
            <Form.Group controlId="ctitrefichier">
              <Form.Control type="text" className={"meds" + (srch == 8 ? " loading notrans" : "")} value={ctitrefichier} onChange={(event) => { sall("ctitrefichier", event.target.value, setCtitrefichier, null, null, null, 8); }} ref={ref => inpt.current[8] = ref} maxLength="100" />
            </Form.Group>
          </fieldset> : null}
          {props.inpts.cdetailfichier && props.inpts.cdetailfichier[2] ? <fieldset className={props.inpts.cdetailfichier[4].nof}><legend>{props.inpts.cdetailfichier[0]}</legend>
            <Form.Group controlId="cdetailfichier">
              <Form.Control as="textarea" className="meds" value={cdetailfichier} onChange={(event) => { sall("cdetailfichier", event.target.value, setCdetailfichier, null, null, null, 9); }} ref={ref => inpt.current[9] = ref} maxLength="1000" rows={1} />
            </Form.Group>
          </fieldset> : null}
          {props.inpts.searcht && props.inpts.searcht[2] ? <fieldset className={props.inpts.searcht[4].nof}><legend>{props.inpts.searcht[0]}</legend>
            <Form.Group controlId="searcht">
              <Form.Control type="text" className="meds" value={searcht} onChange={(event) => { sall("searcht", event.target.value, setSearcht, null, null, null, 10); }} ref={ref => inpt.current[10] = ref} maxLength="300" />
            </Form.Group>
          </fieldset> : null}
          <fieldset><legend>{props.reinit || "Réinitialisation"}</legend>
            <div className="dfac mb-2">
              {props.inpts.largg && props.inpts.largg[2] ? <Form.Group controlId="largg" style={{ marginRight: 5 }}>
                <Form.Label className="meds blkl">{props.inpts.largg[0]}</Form.Label>
                <Form.Control type="number" className="meds smli" value={largg} onChange={(event) => { sall("largg", event.target.value, setLargg, parseInt); }} step="50" min="200" max="1000" />
              </Form.Group> : null}
              {props.inpts.auteg && props.inpts.auteg[2] ? <Form.Group controlId="auteg" style={{ marginLeft: 5 }}>
                <Form.Label className="meds blkl">{props.inpts.auteg[0]}</Form.Label>
                <Form.Control type="number" className="meds smli" value={auteg} onChange={(event) => { sall("auteg", event.target.value, setAuteg, parseInt); }} step="50" min="200" max="1000" />
              </Form.Group> : null}
            </div>
            <div className="dfac" style={{ justifyContent: "center" }}>
              <Form.Group>
                <Button variant="primary" className="meds" onClick={() => setinitial(undefined, 1)}>
                  Réinitialisation
                </Button>
              </Form.Group>
            </div>
          </fieldset>
        </div>
      </div>
    </Form>
  );
});

export default FormInputs;
