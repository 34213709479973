import http from "../http";

const getAll = (props) => {
  return http.get("/");
};

const getData = (data) => {
    return http.get("/data/" + data.join("|||"));
};

export default {
  getAll,
  getData
};
